import React, { useContext, useEffect, useState } from "react";
import SideBar from "../layouts/SideBar";
import HeaderHome from "../layouts/HeaderHome";
import Theme from "../UI/Theme";
import axios from "axios";
import "../../assets/css/home.css";
import Section1Home from "../layouts/Section1Home";
import SectionTeachersHome from "../layouts/SectionTeachersHome";
import SectionActivitiesHome from "../layouts/SectionActivitiesHome";
import { useAuth0 } from "@auth0/auth0-react";
import OverlaySettings from "../layouts/OverlaySettings";
import { UserContext } from "../utils/userContext";
import UploadCV from "../layouts/UploadCV";
import ModalActivityCompleted from "../UI/ModalActivityCompleted";
import ModalRequest from "../layouts/ModalRequest";
import ModalInactive from "../layouts/ModalInactive";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [sideState, setSideState] = useState("show");
  const [isSideOpen, setIsSideOpen] = useState(true); 
  const [sideStateRight, setSideStateRight] = useState("show");
  const [dataAssistant, setDataAssistant] = useState([]);
  const [dataActivity, setDataActivity] = useState([]);
  const [urlActivities, setUrlActivities] = useState();
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useAuth0();
  const { user, authorized } = useContext(UserContext);
  const token = window.localStorage.getItem("token_user");
  const [hideNotification, setHideNotification] = useState(true);
  const { t } = useTranslation();

  const showModalInactive = () =>{
    const modalI = document.querySelector('.modalI')
    
    modalI.classList.add("flex")
    modalI.classList.remove("hidden")

  }
  
  useEffect(() => {
    setLoading(true);
    let url= process.env.REACT_APP_URL_HOME
    let headers = {
      "X-API-KEY": process.env.REACT_APP_X_APIKEY,
    };
    if (authorized && token) {
      // url = process.env.REACT_APP_URL_HOME_DEVELOPMENT+"?vertical="+user.verticals[0].name;
      headers = {
        "X-API-KEY": process.env.REACT_APP_X_APIKEY,
        Authorization: "Bearer " + token,
      };
    }
    
    
    axios
      .get(process.env.REACT_APP_URL_HOME, {
        headers: headers,
      })
      .then((res) => {
        setDataAssistant(res.data.data.assistants);
        setDataActivity(res.data.data.activities);
        setUrlActivities(res.data.data.url_activity_code);
        setLoading(false);
        
      })
      .catch((err) => {
        
        setLoading(false);
        if(err.response?.data?.detail === "User is inactive"){
          showModalInactive()
        }
      });
  }, [authorized, token]);

  const renderForm = () => {
    if (user != null) {
      if (user.cv == null) {
        return <UploadCV />;
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isDesktop = window.innerWidth >= 768;
      const initialState = isDesktop && authorized;
      setIsSideOpen(initialState);
    }
  }, [authorized]);

  return (
    <>
      {authorized && user.plan === "free_trial" && hideNotification ? (
        <div className="relative w-full h-[30px] bg-yellow-600 flex justify-center items-center">
          <p className="text-white text-sm font-bold">
            {t('your-account-is-on-a-free-trial')}
          </p>
          <IoClose
            onClick={() => setHideNotification(false)}
            className="absolute right-2 top-1/2 -translate-y-1/2 text-white cursor-pointer"
          />
        </div>
      ) : null}
      <div className=" flex h-screen overflow-hidden bg-main2 dark:bg-white relative">
        <ModalActivityCompleted />
        <ModalRequest />
        {isAuthenticated && <SideBar sideState={setSideState} setIsSideOpen={setIsSideOpen} isSideOpen={isSideOpen}/>}
        <div className="flex flex-col justify-between items-center w-full md:px-4 sm:px-8 pt-6 overflow-y-scroll">
          <HeaderHome
            sideState={sideState}
            setIsSideOpen={setIsSideOpen}
            isSideOpen={isSideOpen}
            showSide={setSideState}
            showSideRight={setSideStateRight}
            sideStateRight={sideStateRight}
            loading={loading}
          />
          <main className="h-[100dvh] flex-1 w-full">
            <div className="flex h-full w-full flex-col justify-between px-4 sm:px-8 pt-3 gap-10 ">
              <div className="max-w-7xl self-center w-full px-0 md:px-8 pr-0 ">
                <Section1Home
                  assistants={dataAssistant}
                  activities={dataActivity}
                  url={urlActivities}
                  loading={loading}
                />
                <SectionTeachersHome data={dataAssistant} loading={loading} />
                <SectionActivitiesHome
                  data={dataActivity}
                  url={urlActivities}
                  loading={loading}
                />
                {/* <SectionScenariosHome loading={loading}/> */}
              </div>
              <footer></footer>
            </div>
          </main>
        </div>
        <Theme position={"absolute right-5 bottom-5"} />
      </div>
      <OverlaySettings />
      <ModalInactive />
      {renderForm()}
      {/* <OverlayScenarios/> */}
    </>
  );
};

export default Home;
