import { t } from "i18next";
import React from "react";

const OverlayAggendedSO = (props) => {
  return (
    <div className="absolute h-full w-full flex justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-[60] top-0">
      <div className="relative rounded-lg bg-pr-900 flex flex-col justify-between items-center w-[90%] md:w-[30%] p-10 gap-10">
      <div className="w-full flex flex-col justify-center items-center gap-2">
        <h2 className="text-base md:text-xl font-bold text-bunker-600 text-center">
        {t('access-denied')}
        </h2>
        <p className="text-sm md:text-md font-bold w-[90%] text-center">
        {props.msg}
        </p>
      </div>
      <div className="w-full flex flex-col justify-center gap-8 items-center">
        <div className="w-full justify-center gap-4 items-center flex">
          <button onClick={()=>{
              props.setAggendedSO({
                status:false
              })
              props.end(false)
              props.close()
              props.mode("text");
          }} className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition">
            {t('exit')}
          </button>
        </div>
      </div>
    </div>
      
    </div>
  );
};

export default OverlayAggendedSO;
