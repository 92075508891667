import React, { useContext } from "react";
import QA from "../../assets/img/qa.jpg";
import { UserContext } from "../utils/userContext";
import { t } from "i18next";

const VerticalSettings = () => {
  const { authorized, user } = useContext(UserContext);

  return (
    <div className="flex flex-col absolute w-full top-0 left-0 h-full ">
      <img
        src={QA}
        alt="qa"
        className="absolute top-0 left-0 object-cover w-full h-full"
      />
      <div className="bg-[#0000006e] z-20 w-full h-full px-4 md:px-6 pt-0 md:pt-8 mt-14 md:mt-0">
        <h1 className="text-bunker-50 text-xl mb-10 font-medium">
          {t("vertical")}
        </h1>
        <div className="h-full w-full flex justify-center items-center absolute top-0 left-0">
          <h2 className="text-xl text-bunker-50 font-semibold text-start uppercase">
            {authorized && user.verticals[0].name}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default VerticalSettings;
