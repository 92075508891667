import { t } from "i18next";
import React from "react";
import { FaHourglassHalf, FaHourglassEnd } from "react-icons/fa";

const LevelIntermediateEnglish = () => {
  return (
    <div className="w-full flex flex-col gap-10 pb-2">
      {/* <h2 className="text-bunker-50 text-base">You have <strong>INTERMEDIATE</strong> English <strong>B1</strong></h2>
      <p className="text-xs">
      You understand technical conversations in English but have difficulty with accents and need to translate in your head before responding.

      </p> */}

      {/* <div className="w-full flex justify-between items-center">
        <div className="w-[45%] h-auto min-h-[200px] rounded-lg border border-bunker-800 flex flex-col justify-center items-center gap-3 p-2">
          <h3 className="text-bunker-50 text-sm font-bold">
            Objetivo del Hito
          </h3>
          <GiStairsGoal className="text-3xl text-bunker-600" />
          <p className="text-xs text-center">
            Tener confianza en la comunicación de ideas y poder realizar
            entrevistas.
          </p>
        </div>
        <div className="w-[45%] h-auto min-h-[200px] rounded-lg border border-bunker-800 flex flex-col justify-center items-center gap-3 p-2">
          <h3 className="text-bunker-50 text-sm font-bold text-center">
            Habilidades Desarrolladas
          </h3>
          <GiStrong className="text-3xl text-bunker-600" />
          <p className="text-xs text-center">
            Ampliar el vocabulario intermedio, practicar la conversación de
            manera constante, y comunicar ideas con el equipo de ingeniería.
          </p>
        </div>
      </div> */}
      <div className="w-full flex flex-col justify-center items-center mt-2">
        <div className="relative w-[90%] rounded-lg border border-bunker-800 flex flex-col justify-center items-center  p-4 pt-3">
          <h2 className="absolute left-1/2 -translate-x-1/2 -top-5 text-bunker-50 font-bold text-base text-center bg-pr-600 p-2">
            {t("intermedio")}
          </h2>
          <FaHourglassHalf className="text-xl text-bunker-600 text-center my-5" />

          <p className="text-xs text-center">{t("intermedio-1")} </p>
        </div>
        <div className="w-full h-[150px] flex flex-col justify-center items-center">
          <span className="w-px h-full bg-bunker-600"></span>
          <span className="w-px h-full bg-bunker-600"></span>
        </div>
        <div className="relative w-[90%] rounded-lg border border-bunker-800 flex flex-col justify-center items-center p-4 pt-3">
          <h2 className="absolute left-1/2 -translate-x-1/2 -top-5 text-bunker-50 font-bold text-base text-center bg-pr-600 p-2">
            {t('avanzado')}
          </h2>
          <FaHourglassEnd className="text-xl text-bunker-600 text-center my-5" />

          <p className="text-xs text-center">{t("intermedio-2")}</p>
        </div>
      </div>
    </div>
  );
};

export default LevelIntermediateEnglish;
