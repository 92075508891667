import React, { useContext } from "react";
import CardSectionTeachersHome from "../UI/CardSectionTeachersHome";
import CardTeacherHomeSkeleton from "../UI/CardTeacherHomeSkeleton";
import "swiper/css";
import "swiper/css/navigation";
import "../../assets/css/slider.css"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import { t } from "i18next";
import * as amplitude from '@amplitude/analytics-browser';
import { UserContext } from "../utils/userContext";

amplitude.init('f8358a3b2025d29fe2822013871b15f1');


const SectionTeachersHome = (props) => {

  const {user} = useContext(UserContext)
  

  const handleButtonClick = (props) => {
    
    if(user.id != null){
      amplitude.track('Entra al assistant', {
        userId: user.id, 
        userEmail: user.email, 
        userName: user.full_name ,
        buttonName: 'Assistant',
        page: 'Home',
        assistant:props.name,
        assistantId:props.assistant_id,
        assistantRole:props.role
      });
    }
  };

  return (
    <div className="w-full flex flex-col gap-5 my-6">
      <h2 className="text-base font-bold text-bunker-50 dark:text-[#7d7d7d]">
        {t('teachers')}
      </h2>
      <div className="w-full gap-y-4 gap-8 ">
        {!props.loading ? (
          // <Slider {...settings}>
          <Swiper navigation={true} modules={[Navigation]} slidesPerView={"auto"} centeredSlides={false} spaceBetween={30} className="swiper-container">
            {props.data.map((assistant, index) => (
              <SwiperSlide key={index} className="swiper-slide">
                <CardSectionTeachersHome data={assistant}  clickAmplitude={handleButtonClick}/>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          // </Slider>
          <>
          <div className="flex md:hidden w-full justify-between items-center gap-2">
          <CardTeacherHomeSkeleton />

          </div>
          <div className="hidden md:flex flex-col md:flex-row gap-y-4 gap-8 justify-start items-center">
            <CardTeacherHomeSkeleton />
            <CardTeacherHomeSkeleton />
            <CardTeacherHomeSkeleton />
            <CardTeacherHomeSkeleton />
          </div>
          </>
          
        )}
      </div>
    </div>
  );
};

export default SectionTeachersHome;
