import { t } from "i18next";
import React from "react";
import { FaAngleDoubleLeft } from "react-icons/fa";

const MenuSettings = ({ setSelectedLayout, selectedLayout, hideMenu }) => {
  const menuItems = [
    { name: `${t('about-me')}`, layout: "about me" },
    { name: `${t('objective-and-achievement')}`, layout: "level" },
    { name: `${t('profile')}`, layout: "profile" },
    { name: `${t('account')}`, layout: "account" },
    { name: `${t('vertical')}`, layout: "vertical" },
  ];
  return (
    <ul className="w-full flex flex-col items-start gap-2 justify-start ">
      <div
        onClick={hideMenu}
        className="md:hidden group p-2 rounded-full hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center cursor-pointer"
      >
        <FaAngleDoubleLeft className="text-[#94949D] dark:text-[#7D7D7D] " />
      </div>
      {menuItems.map((item, index) => (
        <li
          key={index}
          className={`py-2 px-3 hover:text-bunker-50 text-sm cursor-pointer rounded-xl w-full ${
            selectedLayout === item.layout
              ? "bg-bunker-800 text-bunker-50"
              : "bg-transparent "
          } custom-transition`}
          onClick={() => setSelectedLayout(item.layout)}
        >
          <span>{item.name}</span>
        </li>
      ))}
    </ul>
  );
};

export default MenuSettings;
