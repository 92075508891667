import axios from "axios";
import React, { useState } from "react";
import CheckImg from "../../assets/img/check.webm";
import { t } from "i18next";

const OverlayQuestions = (props) => {

  const token = window.localStorage.getItem("token_user");
  const [schema, setSchema] = useState({
    session_log_id: props.session_id,
    feedback_question_id: props.data?.feedback_question.feedback_question_id,
    rating_answer: 5,
    text_answer: "",
  });
  const [showTextArea, setShowTextArea] = useState(false);
  const [tanks, setTanks] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleStarRating = (e) => {
    setSchema({
      ...schema,
      rating_answer: parseInt(e.target.value),
    });
  };

  const handleText = (e) => {
    setSchema({
      ...schema,
      text_answer: e.target.value,
    });
  };

  const postAnswer = () => {
    setLoading(true);

    axios
      .post(process.env.REACT_APP_SUBMIT_QUESTIONS, schema, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setLoading(false);
        setTanks(true);
        setTimeout(() => {
          setTanks(false);
          props.close(false);
          window.location.reload()
        }, 5000);
      })
      .catch((err) => {
        
      });
  };

  return (
    <div
      id="modalQ"
      className="absolute h-full w-full flex justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-[60] top-0"
    >
      <div className="relative rounded-lg bg-pr-900 flex flex-col justify-between items-center w-[90%] md:w-[30%] p-10 gap-5 md:gap-10">
        {!tanks ? (
          <>
            <div className="w-full flex flex-col justify-center items-center gap-2">
              <h2 className="text-xl font-bold text-bunker-600 text-center">
                {t('feedback')}
              </h2>
            </div>
            <div className="w-full flex flex-col justify-center gap-8 items-center">
              <p className="text-base font-bold w-[90%] text-center">
                {props.data?.feedback_question.question_text}
              </p>
              <div className="rating rating-lg">
                <input
                  type="radio"
                  name="rating-2"
                  value={1}
                  onChange={handleStarRating}
                  className="mask mask-star-2 bg-orange-400"
                  defaultChecked
                />
                <input
                  type="radio"
                  name="rating-2"
                  value={2}
                  onChange={handleStarRating}
                  className="mask mask-star-2 bg-orange-400"
                />
                <input
                  type="radio"
                  name="rating-2"
                  value={3}
                  onChange={handleStarRating}
                  className="mask mask-star-2 bg-orange-400"
                />
                <input
                  type="radio"
                  name="rating-2"
                  value={4}
                  onChange={handleStarRating}
                  className="mask mask-star-2 bg-orange-400"
                />
                <input
                  type="radio"
                  name="rating-2"
                  value={5}
                  onChange={handleStarRating}
                  className="mask mask-star-2 bg-orange-400"
                  
                />
              </div>
            </div>

            <div className="w-full flex flex-col gap-4 justify-center items-center">
              <span
                onClick={() => {
                  setShowTextArea(!showTextArea);
                }}
                className="text-xs cursor-pointer hover:underline custom-transition"
              >
                {t('write-a-comment-if-you-want-to-provide-further-details')}
              </span>
              {showTextArea && (
                <textarea
                  onChange={handleText}
                  className="w-full h-24 border text-sm bg-pr-600 checked:border-bunker-800 border-bunker-900 dark:border-bunker-700 rounded-xl p-2 outline-none custom-transition"
                  placeholder="Leave your comment here"
                ></textarea>
              )}
            </div>
            <div className="w-full justify-center gap-4 items-center flex">
              <button
                onClick={postAnswer}
                className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
              >
                {loading ? (
                  <span className="loading loading-spinner loading-xs text-[#fafafa]"></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </>
        ) : (
          <div className="w-full flex flex-col justify-center items-center gap-4">
            <h2 className="text-xl font-bold text-bunker-600 text-center">
              {t('thank-you-for-your-feedback')}
            </h2>
            <p className="text-md font-bold w-[90%] text-center">
              {t('your-feedback-is-very-important-to-us')}
            </p>
            <video src={CheckImg} autoPlay muted></video>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverlayQuestions;
