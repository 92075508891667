import { t } from "i18next";
import React from "react";
import { MdLocationOn } from "react-icons/md";
import { PiStrategyFill } from "react-icons/pi";
import { RiSpeakFill } from "react-icons/ri";
import { TbVocabulary } from "react-icons/tb";

const SkillsAssistant = (props) => {
  return (
    <div className="w-full flex flex-col gap-4 justify-start items-start">
      <h2 className="font-semi-bold line-clamp-1 text-ellipsis text-start overflow-hidden whitespace-normal text-bunker-600 dark:text-[#7d7d7d] text-sm">
        {t('skills')}
      </h2>
      <div className="w-full flex justify-start gap-4 flex-wrap">
        <div className="w-full flex">
          <div className="w-1/2 flex flex-col justify-start items-start gap-8">
            <div className="flex items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                <PiStrategyFill className="text-bunker-600 text-base" />
              </div>
              <div className="flex flex-col justify-start items-start max-w-[70%]">
                <h3 className="text-start text-[#a3a3a3] text-sm ">
                  {t('strategic-communication')}
                </h3>
                <p className="text-start text-xs text-bunker-50 font-bold line-clamp-2">
                  {props.data?.strategic_communication_skills}
                </p>
              </div>
            </div>

            <div className="flex  items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                <RiSpeakFill className="text-bunker-600 text-base" />
              </div>
              <div className="flex flex-col justify-start items-start max-w-[70%]">
                <h3 className="text-start text-[#a3a3a3] text-sm">
                  {t('technical-communication')}
                </h3>
                <p className="text-start text-xs text-bunker-50 font-bold line-clamp-2">
                  {props.data?.technical_communication_skills}
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/2 flex flex-col justify-start items-start gap-8">
            <div className="flex items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                <MdLocationOn className="text-bunker-600 text-base" />
              </div>
              <div className="flex flex-col justify-start items-start max-w-[70%]">
                <h3 className="text-start text-[#a3a3a3] text-sm">
                  {t('preparation-and-reporting')}
                </h3>
                <p className="text-start text-xs text-bunker-50 font-bold line-clamp-2">
                  {props.data?.preparation_and_reporting_skills}
                </p>
              </div>
            </div>

            <div className="flex  items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                <TbVocabulary className="text-bunker-600 text-base" />
              </div>
              <div className="flex flex-col justify-start items-start max-w-[70%]">
                <h3 className="text-start text-[#a3a3a3] text-sm">
                  {t('vocabulary-proficiency')}
                </h3>
                <p className="text-start text-xs text-bunker-50 font-bold line-clamp-2">
                  {props.data?.vocabulary_proficiency}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsAssistant;
