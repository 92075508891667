import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouter from './components/router/AppRouter';
import { FileProvider } from './components/utils/FileContext';


function App() {
  return (
    <BrowserRouter>
    <FileProvider>
      <AppRouter />
    </FileProvider>
  </BrowserRouter>
  );
}

export default App;
