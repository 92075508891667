import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import { HiMiniVideoCamera, HiMiniVideoCameraSlash } from "react-icons/hi2";
import { MdCallEnd } from "react-icons/md";
import { useAuth0 } from "@auth0/auth0-react";
import * as amplitude from "@amplitude/analytics-browser";
import OverlayNoAggended from "../layouts/OverlayNoAggended";
import Logo from "../../assets/img/Logo1.png";
import { IoClose } from "react-icons/io5";
import SoundSC from "../../assets/sounds/start_call.mp3";
import SoundEC from "../../assets/sounds/end_call.mp3";
import SoundCC from "../../assets/sounds/connect_call.mp3";
import ModalLoadingVideoCall from "../UI/ModalLoadingVideoCall";
import Timer from "../UI/Timer";
import { IoMdSend } from "react-icons/io";
import { TbHandStop } from "react-icons/tb";
import OverlayAggendedSO from "../layouts/OverlayAggendedSO";

amplitude.init("f8358a3b2025d29fe2822013871b15f1");

const VideoCallPage = (props) => {
  const token = window.localStorage.getItem("token_user");

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const audioChunks = useRef([]);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(true);

  const peerConnectionRef = useRef(null);
  const [streamId, setStreamId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [sessionClientAnswer, setSessionClientAnswer] = useState(null);
  const videoElement = useRef(null);
  const videoElementDefault = useRef(null);
  const [stream, setStream] = useState(null);
  const [loadingBot, setLoadingBot] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const audioRef = useRef(null);
  const [thinking, setThinking] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [showInterrupt, setShowInterrupt] = useState(false);
  const contadorIntroRef = useRef(0);

  const trackStateIntervalRef = useRef(null);

  const { user } = useAuth0();

  const playSound = (sound, loop = false) => {
    audioRef.current.src = sound;
    audioRef.current.loop = loop;
    audioRef.current.play().catch((error) => {
      console.error("Error reproduciendo el audio:", error);
    });
  };

  const stopSound = () => {
    ("stop sound");

    audioRef.current.pause();
    audioRef.current.currentTime = 0; // Reinicia el audio
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 128; // Ajuste del tamaño de la FFT
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        source.connect(analyserRef.current);

        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        setRecording(true);

        mediaRecorder.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          // const audioUrl = URL.createObjectURL(audioBlob);
          // setAudioURL(audioUrl);
          audioChunks.current = [];
          uploadAudio(audioBlob);
        };
      })
      .catch((error) => console.error("Error accessing audio devices.", error));
  };

  const stopRecording = () => {
    setLoadingBot(true);
    setThinking(true);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      setLoadingAudio(true);
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    }
  };

  const uploadAudio = (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");

    try {
      axios
        .post(process.env.REACT_APP_URL_AUDIO, formData, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          props.sendMessage(res.data.transcription);
        });
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  const toggleCamera = () => {
    if (isCameraActive) {
      // Si la cámara está activa, la apagamos
      const video = document.querySelector(".video");
      if (video) {
        video.srcObject = null;
      }
    } else {
      // Si la cámara está inactiva, la activamos
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function (stream) {
            const video = document.querySelector(".video");
            if (video) {
              video.srcObject = stream;
            } else {
              console.error("La referencia de video no está definida.");
            }
          })
          .catch(function (error) {
            console.error("Error al acceder a la cámara:", error);
          });
      }
    }
    // Cambiamos el estado de la cámara
    setIsCameraActive(!isCameraActive);
  };

  const startVideo = async () => {
    // setLoadingBot(false);
    const apiConfig = {
      key: process.env.REACT_APP_API_DID,
      url: "https://api.d-id.com",
      service: "talks",
    };

    if (
      peerConnectionRef.current?.signalingState === "stable" ||
      peerConnectionRef.current?.iceConnectionState === "connected"
    ) {
      await axios
        .post(
          `${apiConfig.url}/talks/streams/${streamId}`,
          {
            session_id: sessionId,
            script: {
              type: "audio",
              audio_url: props.audio,
            },
            config: {
              fluent: true,
              // align_driver: false,
              // normalization_factor: 1.0,
              // auto_match: false
              stitch: true,
            },
            audio_optimization: 2,
          },
          {
            headers: {
              Authorization: `Basic ${apiConfig.key}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setThinking(false);
          videoElement.current.muted = false;
          videoElement.current.srcObject = stream;
          videoElement.current.loop = false;
        })
        .catch((err) => {
        });
    }
  };

  const stopVideo = () => {
    videoElement.current.stop = true;
    setDisabledBtn(false);

    videoElement.current.muted = true;

    const video = document.getElementById("video_bot");
    video.classList.add("-z-10");
    video.classList.remove("z-20");
    setShowInterrupt(false);
  };

  const connect = async () => {
    ("conecta");

    const apiConfig = {
      key: process.env.REACT_APP_API_DID, // La clave API desde la variable de entorno
      url: "https://api.d-id.com", // URL de la API configurada manualmente
      service: "talks", // El servicio configurado manualmente
    };
    setLoadingBot(true);
    try {
      const sessionResponse = await axios.post(
        `${apiConfig.url}/${apiConfig.service}/streams`,
        {
          source_url: props.assistant.did_image_url,
        },
        {
          headers: {
            Authorization: `Basic ${apiConfig.key}`,
            "Content-Type": "application/json",
          },
        }
      );

      const {
        id: newStreamId,
        offer,
        ice_servers: iceServers,
        session_id: newSessionId,
      } = sessionResponse.data;
      setStreamId(newStreamId);
      setSessionId(newSessionId);

      // Aquí creamos la conexión con los servidores ICE obtenidos de la respuesta
      const pc = new RTCPeerConnection({ iceServers });

      peerConnectionRef.current = pc;
      pc.oniceconnectionstatechange = () => {

        if (pc.iceConnectionState === "disconnected") {
          setTimeout(() => {
            connect();
          }, 2000);
          // restartIce(newStreamId, newSessionId)
          setDisabledBtn(true);
        } else if (pc.iceConnectionState === "connected") {
          setDisabledBtn(false);
        }
      };

      pc.onicecandidate = async (event) => {
        if (event.candidate) {
          try {
            await axios.post(
              `${apiConfig.url}/${apiConfig.service}/streams/${newStreamId}/ice`,
              {
                candidate: event.candidate.candidate,
                sdpMid: event.candidate.sdpMid,
                sdpMLineIndex: event.candidate.sdpMLineIndex,
                session_id: newSessionId,
              },
              {
                headers: {
                  Authorization: `Basic ${apiConfig.key}`,
                  "Content-Type": "application/json",
                },
              }
            );
          } catch (error) {
            console.error("Error sending ICE candidate:", error);
          }
        }
      };

      pc.ontrack = (event) => {
        if (event.streams && event.streams[0]) {
          const receivedStream = event.streams[0];
          setStream(receivedStream);

          videoElement.current.srcObject = receivedStream;

          const videoTracks = receivedStream.getVideoTracks();
          if (videoTracks.length > 0) {
            const videoTrack = videoTracks[0];

            videoTrack.onmute = () => {
              setShowInterrupt(false);
              setDisabledBtn(false);
              const video = document.getElementById("video_bot");
              video.classList.add("-z-10");
              video.classList.remove("z-20");
            };

            videoTrack.onunmute = () => {
              setDisabledBtn(true);
              contadorIntroRef.current += 1;
              if (contadorIntroRef.current > 1) {
                setShowInterrupt(true);
                setLoadingBot(false)
              }

              const video = document.getElementById("video_bot");
              video.classList.remove("-z-10");
              video.classList.add("z-20");
            };
          }
        }
        if (videoElement.current.paused) {
          videoElement.current
            .play()
            .then((_) => {})
            .catch((e) => {});
        }
      };

      const answer = await createPeerConnection(pc, offer);
      setSessionClientAnswer(answer);
      try {
        // Enviar la respuesta SDP a D-ID
        await axios.post(
          `${apiConfig.url}/${apiConfig.service}/streams/${newStreamId}/sdp`,
          {
            answer: answer,
            session_id: newSessionId,
          },
          {
            headers: {
              Authorization: `Basic ${apiConfig.key}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
      }
    } catch (error) {
    }
  };

  const closePC = () => {
    if (peerConnectionRef.current) {
      // Remover listeners
      peerConnectionRef.current.ontrack = null;
      peerConnectionRef.current.onicecandidate = null;
      peerConnectionRef.current.oniceconnectionstatechange = null;

      // Cerrar la conexión
      peerConnectionRef.current.close();

      // Limpiar streams
      if (videoElement.current && videoElement.current.srcObject) {
        videoElement.current.srcObject
          .getTracks()
          .forEach((track) => track.stop());
        videoElement.current.srcObject = null;
      }

      peerConnectionRef.current = null;
    }
  };

  const createPeerConnection = async (pc, offer) => {
    if (!pc) return;

    await pc.setRemoteDescription(offer);
    const answer = await pc.createAnswer();
    await pc.setLocalDescription(answer);
    return answer;
  };

  const playIdleVideo = () => {
    videoElementDefault.current.srcObject = undefined;
    videoElementDefault.current.src = props.assistant.did_video_url;
    videoElementDefault.current.loop = true;
  };

  

  const endCall = () => {
    closePC();
    axios
      .get(
        process.env.REACT_APP_URL_END_VIDEOCALL +
          props.session_id +
          "/",
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        amplitude.track("Termina la llamada", {
          userId: user.id,
          userEmail: user.email,
          userName: user.full_name,
          buttonName: "Call out",
          page: "VideoCall",
          time_end: res.data.end_time,
          time_start: res.data.start_time,
          assistant: props.assistant.name,
          assistantId: props.assistant.assistant_id,
          assistantRole: props.assistant.role,
        });

        // window.location.reload()
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    window.RTCPeerConnection = (
      window.RTCPeerConnection ||
      window.webkitRTCPeerConnection ||
      window.mozRTCPeerConnection
    ).bind(window);
    playIdleVideo();
    connect();
    toggleCamera();
    setDisabledBtn(true);

    return () => {
      closePC();
      // Limpiar intervalos adicionales si es necesario
      if (trackStateIntervalRef.current) {
        clearInterval(trackStateIntervalRef.current);
        trackStateIntervalRef.current = null;
      }
    };
  }, [props.agended]);

  useEffect(() => {
    if (props.audio) {
      startVideo();
    }
  }, [props.audio]);

  useEffect(() => {
    playSound(SoundSC, true);
    setModalLoading(true);
    const handleBeforeUnload = (event) => {
      // Envía una petición a tu backend
      endCall();

      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {

    if (props.status === "audio_loading") {
      setLoadingBot(true);
      setThinking(false);
    } 
    // else {
    //   setLoadingBot(false);
    // }
  }, [props.status]);

  useEffect(() => {
    if (props.type === "transmission_completed") {
      stopSound();
      setShowTimer(true);
      playSound(SoundCC, false);
      setModalLoading(false);
    }
  }, [props.type]);

  useEffect(() => {
    if (props.aggendedSO.status === true) {
      stopSound();
    }
  }, [props.aggendedSO.status]);

  useEffect(() => {
    const handleKeyPress = (event) => {

      if(recording){
        if (event.code === 'Space') { // Detectar la tecla 'Space'
          stopRecording();
        }
      }else{
        if (event.code === 'Space') { // Detectar la tecla 'Space'
          startRecording();
        }
      }
    };

    // Agregar el evento al documento
    document.addEventListener('keydown', handleKeyPress);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [recording]);

  return (
    <div className="absolute h-full min-h-screen w-full flex flex-col justify-between items-center">
      <audio ref={audioRef} hidden />
      <div className="relative h-[90%] w-full flex  justify-center items-center bg-black dark:bg-white">
        {modalLoading && <ModalLoadingVideoCall />}
        <img
          src={Logo}
          className="absolute right-4 top-0 z-[29] md:bottom-2 md:top-auto w-[100px] h-[50px] md:w-[200px] md:h-[100px]"
          alt=""
        />
        {props.suggestions && (
          <div className="absolute top-4 right-2 min-w-[150px] max-w-[400px] w-auto min-h-[100px] h-auto bg-[#f9f9f9] dark:bg-[#29292E] z-50 flex justify-center items-center p-4 rounded-lg">
            <IoClose
              onClick={() => props.setSuggestions(null)}
              className="absolute top-1 right-1 text-[#29292E] dark:text-[#f9f9f9] cursor-pointer "
            />
            <p className="text-black text-sm font-medium">
              {props.suggestions}
            </p>
          </div>
        )}
        <div className="relative w-full  h-full flex flex-col justify-evenly items-center gap-5 md:gap-0 md:flex-row  md:p-10">
          <div className="w-[150px]  h-[200px] md:w-[300px] md:h-[300px] absolute  bottom-2  left-2 md:bottom-10 md:left-10 rounded-lg">
            <div
              className={`rounded-full w-full h-full ${
                recording ? "animate-pulse playing " : ""
              }`}
            />
            <video
              autoPlay={true}
              className="video rounded-lg w-full absolute top-0 left-0 h-full object-cover z-40"
            />
            <img
              src={user.picture}
              alt="user"
              className="w-full h-full object-cover rounded-lg absolute top-0 left-0 z-[35]"
            />
          </div>
          <div className="relative w-full  h-full md:w-[50%]">
            {/* {thinking && (
              <div className="absolute w-full h-full rounded-lg md:rounded-none bg-[#00000081] flex justify-center items-center z-30 ">
                <span className="p-1 px-2 rounded-lg flex items-center justify-center bg-white gap-1 text-black text-xs absolute top-2 right-2">
                  thinking
                  <span className="loading loading-dots loading-xs"></span>
                </span>
              </div>
            )} */}
            <video
              ref={videoElementDefault}
              // onEnded={handleVideoEnd}
              // onPlay={handleVideoPlay}
              autoPlay
              playsInline
              className="absolute top-0 right-0 bottom-0 left-0 w-full h-full object-cover md:rounded-lg z-10"
            />
            <video
              ref={videoElement}
              autoPlay
              playsInline
              id="video_bot"
              className="absolute top-0 right-0 bottom-0 left-0  z-20 w-full h-full object-cover md:rounded-lg"
            />
          </div>
        </div>
      </div>
      <div className="relative h-[10%] w-full flex justify-center items-center bg-pr-600 dark:bg-[#f9f9f9] px-10">
        {showTimer && (
          <div className="absolute h-full flex justify-center items-center right-2 -top-14 z-[59] md:top-auto md:right-auto md:left-10 gap-2">
            <span className=" text-xs md:text-base font-semibold px-4 py-2 rounded-full bg-pr-100 flex justify-center items-center gap-2">
              <span className="hidden md:visible">Time:</span> <Timer />
            </span>
          </div>
        )}
        <div className="w-1/2 h-full flex justify-center items-center gap-2">
          {recording ? (
            <button
              onClick={stopRecording}
              className="p-2 rounded-full border-[1px]  border-bunker-500 dark:border-none hover:border-bunker-800 dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
            >
              <IoMdSend className="text-bunker-600 dark:text-[#7d7d7d] text-xl" />
            </button>
          ) : loadingBot ? (
            <span className="loading loading-spinner loading-sm text-green-400"></span>
          ) : (
            <button
              id="mic"
              disabled={disabledBtn ? true : false}
              onClick={startRecording}
              className="p-2 rounded-full disabled:opacity-80 disabled:border-green-800 disabled:text-green-800 border-[1px] disabled:cursor-default border-green-400 dark:border-none hover:green-600 dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
            >
              <FaMicrophone className="text-green-400 dark:text-[#7d7d7d] text-xl" />
            </button>
          )}
          {showInterrupt && (
            <button
              onClick={stopVideo}
              className="p-2 rounded-full border-[1px] border-red-500 dark:border-none hover:bg-red-800 dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
            >
              <TbHandStop className="text-red-600 dark:text-[#7d7d7d] text-xl" />
            </button>
          )}
          <button
            onClick={toggleCamera}
            className="p-2 rounded-full border-[1px] border-[#29292E] dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
          >
            {isCameraActive ? (
              <HiMiniVideoCameraSlash className="text-[#515153] dark:text-[#7d7d7d] text-xl" />
            ) : (
              <HiMiniVideoCamera className="text-[#515153] dark:text-[#7d7d7d] text-xl" />
            )}
          </button>
          <button
            onClick={() => {
              stopSound();
              playSound(SoundEC, false);
              setTimeout(() => {
                props.setVideoCallActive(false);
                endCall();
                props.setQuestions(true);
                props.mode("text");
              }, 300);

              // handleSpeak("Hello, how can I assist you today?");
            }}
            className="p-2 rounded-full border-[1px] border-red-600 dark:border-none bg-red-500 hover:bg-red-600  custom-transition flex justify-center items-center  cursor-pointer"
          >
            <MdCallEnd className="text-xl text-bunker-50" />
          </button>
          {/* <button onClick={startVideo}>Start</button> */}
        </div>
      </div>
      {props.agended && (
        <OverlayNoAggended
          setAgended={props.setAgended}
          end={props.setVideoCallActive}
          close={closePC}
          mode={props.mode}
          assistantId={props.assistant.assistant_id}
        />
      )}
      {props.aggendedSO.status && (
        <OverlayAggendedSO
          setAggendedSO={props.setAggendedSO}
          msg={props.aggendedSO.message}
          end={props.setVideoCallActive}
          close={closePC}
          mode={props.mode}
        />
      )}
    </div>
  );
};

export default VideoCallPage;
