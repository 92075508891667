import { t } from "i18next";
import React from "react";

const ModalLoadingVideoCall = () => {
  return (
    <div className="absolute top-2 md:bottom-14 md:top-auto left-1/2 -translate-x-1/2 rounded-lg p-4 flex flex-col justify-center items-center gap-5 w-[90%] md:w-auto h-auto md:h-[150px] bg-gray-100 bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 border border-gray-100 z-[55]">
      <p className="text-xs md:text-sm font-semibold text-white text-center">
        {t(
          "uploading-now-your-avatar-to-live-an-interactive-experience-in-real-time"
        )}
      </p>
      <span className="loading loading-spinner loading-sm text-bunker-600"></span>
    </div>
  );
};

export default ModalLoadingVideoCall;
