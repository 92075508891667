import React from "react";

const SkillsAssistantSkeleton = () => {
  return (
    <div className="w-full flex flex-col gap-4 justify-start items-start">
      <h2 className="font-semi-bold line-clamp-1 text-ellipsis text-start overflow-hidden whitespace-normal text-[#A3A3A3] dark:text-[#7d7d7d] text-sm">
        Skills:
      </h2>
      <div className="w-full flex justify-start gap-4 flex-wrap">
        <div className="w-full flex">
          <div className="w-1/2 flex flex-col justify-start items-start gap-8">
            <div className="flex items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] rounded-full border-[1px] border-[#a3a3a3] skeleton bg-[#26272B]"></div>
              <div className="flex flex-col justify-start items-start max-w-[70%] gap-4">
                <div className="h-1 w-28  skeleton bg-[#26272B]"></div>
                <div className="h-1 w-20 skeleton bg-[#26272B]"></div>
              </div>
            </div>

            <div className="flex  items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] rounded-full border-[1px] border-[#a3a3a3] skeleton bg-[#26272B]"></div>
              <div className="flex flex-col justify-start items-start max-w-[70%] gap-4">
                <div className="h-1 w-28 skeleton bg-[#26272B]"></div>
                <div className="h-1 w-20 skeleton bg-[#26272B]"></div>
              </div>
            </div>
          </div>
          <div className="w-1/2 flex flex-col justify-start items-start gap-8">
            <div className="flex items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] skeleton bg-[#26272B] rounded-full border-[1px] border-[#a3a3a3]"></div>
              <div className="flex flex-col justify-start items-start max-w-[70%] gap-4">
                <div className="h-1 w-28 skeleton bg-[#26272B]"></div>
                <div className="h-1 w-20 skeleton bg-[#26272B]"></div>
              </div>
            </div>

            <div className="flex  items-start gap-2 w-full">
              <div className="w-[30px] h-[30px] skeleton bg-[#26272B] rounded-full border-[1px] border-[#a3a3a3]"></div>
              <div className="flex flex-col justify-start items-start max-w-[70%] gap-4">
                <div className="h-1 w-28 skeleton bg-[#26272B]"></div>
                <div className="h-1 w-20 skeleton bg-[#26272B]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsAssistantSkeleton;
