import React, { useContext, useState } from "react";
import { UserContext } from "../utils/userContext";
import LevelBasicEnglish from "../UI/LevelBasicEnglish";
import LevelIntermediateEnglish from "../UI/LevelIntermediateEnglish";
import LevelAdvancedEnglish from "../UI/LevelAdvancedEnglish";
import axios from "axios";
import { t } from "i18next";

const LevelEnglishSettings = () => {
  const { user, setUser, authorized } = useContext(UserContext);
  const [valueSelect, setValueSelect] = useState();
  const [loading, setLoading] = useState(false);
  const token = window.localStorage.getItem("token_user");


  const handleChange = (e) => {
    setValueSelect({ english_level: e.target.value });
  };

  const renderLevelEnglish = (level) => {
    if (level === "Basic") {
      return <LevelBasicEnglish />;
    } else if (level === "Intermediate") {
      return <LevelIntermediateEnglish />;
    } else if (level === "Advanced") {
      return <LevelAdvancedEnglish />;
    }
  };

  const setLevelUser = () => {
    setLoading(true);
    axios
      .patch(process.env.REACT_APP_URL_GET_USERS + user.id+"/", valueSelect, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
        Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setUser(res.data)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  return (
    <div className="flex flex-col h-full">
      <h1 className="text-bunker-500 text-xl mb-10 font-medium">{t('objective-and-achievement')}</h1>
      {authorized && user.english_level !== "" && (
        <div className="flex flex-col h-full overflow-y-scroll">
          {renderLevelEnglish(user.english_level)}
          {/* <span className="shrink-0 block bg-pr-200 h-[1px] w-full my-3 "></span>
          <div className="flex flex-col justify-center items-center gap-3">
            <h2 className="text-bunker-50 text-base text-center">
              Choose your level of English
            </h2>
            <select
              onChange={handleChange}
              className="select select-bordered border-bunker-800  w-full max-w-xs"
            >
              <option disabled selected>
                English Level
              </option>
              <option value="Basic">Basic</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
            {valueSelect != null && <button
              onClick={setLevelUser}
              className="px-4 py-2 text-sm bg-pr-800 border border-bunker-800 hover:bg-bunker-800 hover:border-bunker-600 bg-transparent rounded-full custom-transition"
            >
              {loading ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Update"
              )}
            </button>}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default LevelEnglishSettings;
