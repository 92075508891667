import { t } from "i18next";
import React from "react";
import { IoIosChatboxes } from "react-icons/io";
import { Link } from "react-router-dom";

const CardSectionTeachersHome = (props) => {
  return (
      <div className="h-[400px] w-[288px] rounded-xl relative group bg-pr-500 dark:bg-[#f9f9f9] custom-transition flex-shrink-0 ">
      <div className="w-full h-1/2 flex justify-center items-center">
        <img
          src={props.data.image}
          alt=""
          className="w-full h-full  object-cover rounded-t-xl"
        />
      </div>
      <div className="w-full h-1/2 flex flex-col justify-between p-3">
        <div className="flex flex-col justify-between gap-2">
        <h2 className="text-bunker-50 dark:text-black font-semibold text-sm">{props.data.name}</h2>
        <p className="text-xs text-[#7d7d7d] dark:font-bold line-clamp-1">{props.data.role}</p>
        <p className="text-bunker-50 dark:text-[#7d7d7d] text-xs line-clamp-4">
          {props.data.description}
        </p>
        </div>
        <Link
        onClick={()=> props.clickAmplitude(props.data)}
          to={`/chat/assistant/${props.data.assistant_id}`}
          className="btn bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
        >
          <IoIosChatboxes className="text-[#A2A2AC] text-sm"/> {t('go-chat')}
        </Link>
      </div>
    </div>
  );
};

export default CardSectionTeachersHome;
