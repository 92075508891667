import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import { UserContext } from "../utils/userContext";
import { t } from "i18next";

const AccountSettings = () => {

  const {logout} = useAuth0()
  const {user, authorized} = useContext(UserContext)
  return (
    <div className="flex flex-col h-full">
      <h1 className="text-bunker-500 text-xl mb-10 font-medium">{t('account')}</h1>
      <div className="w-full h-full flex flex-col justify-between pb-4">
        <div className="w-full border rounded-xl flex justify-between items-center p-3">
          <div className="flex flex-col">
            <span className="text-xs">{t('your-current-plan')}</span>
            <p className="text-bunker-50 text-base">{authorized && user.plan != null ? user.plan : `${t('free')}`}</p>
          </div>
          <button class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-bunker-50 dark:text-bunker-50 focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
            <a href="https://www.englishcode.ai/matriculas" rel="noreferrer" target="_blank" class=" text-xs relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              {t('update')}
            </a>
          </button>
        </div>
        <div className="w-full">
          <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="px-4 py-2 text-sm hover:bg-main-side bg-transparent rounded-full custom-transition">{t('log-out')}</button>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
