import axios from "axios";
import React, { useContext, useState } from "react";
import { UserContext } from "../utils/userContext";
import { t } from "i18next";

const CardSectionActivitiesHome = (props) => {
  const { user, authorized } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const token = window.localStorage.getItem("token_user");

  const showModalInactive = () =>{
    const modalI = document.querySelector('.modalI')
    
    modalI.classList.add("flex")
    modalI.classList.remove("hidden")

  }

  const redirectActivity = () => {
    if (authorized) {
      setLoading(true);
      let body = {
        parent_activity_id: props.data.parent_activity_id,
        vertical: user.verticals[0].name,
        user_id: user.id,
      };

      axios
        .post(process.env.REACT_APP_URL_RANDOM_CODE, body, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            "Authorization": "Bearer " + token,
          },
        })
        .then((res) => {
          setLoading(false);
          window.open(
            props.url + res.data.random_code + "/" + res.data.user_activity_id,
            "_blank"
          );
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.message === "No codes found") {
            props.show();
          }
          if(err.response.data.detail === "User is inactive"){
            showModalInactive()
          }
        });
    }
  };

  return (
    <div
      onClick={()=>{
        props.clickAmplitude(props.data)
        redirectActivity()
      }}
      className="h-[146px] w-[300px] p-4 flex gap-4 bg-pr-500 dark:bg-[#f9f9f9] rounded-xl hover:bg-pr-300 hover:dark:bg-[#ECECEC] custom-transition cursor-pointer flex-shrink-0 "
    >
      <img
        src={props.data.image}
        alt=""
        className="w-[30%] h-full object-cover rounded-xl"
      />
      <div className="w-[70%] h-full flex flex-col justify-between ">
        <div>
          <h3 className="text-sm text-bunker-50 dark:text-[#7d7d7d] font-semibold">
            {props.data.name}
          </h3>
          <p className="text-xs text-bunker-50 dark:text-[#7d7d7d] line-clamp-2">
            {props.data.description}
          </p>
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-xs text-[#A2A2AC] font-semibold">{t('advance')}</p>
          {loading && (
            <span className="loading loading-spinner loading-xs text-white"></span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardSectionActivitiesHome;
