import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { FaMicrophone, FaStop } from "react-icons/fa";

const Mic = (props) => {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const [loadingTranscription, setLoadingTranscription] = useState(false);
  const token = window.localStorage.getItem("token_user");

  const showModalInactive = () => {
    const modalI = document.querySelector(".modalI");

    modalI.classList.add("flex");
    modalI.classList.remove("hidden");
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 128; // Ajuste del tamaño de la FFT
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        source.connect(analyserRef.current);

        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        setRecording(true);
        props.recording(true);

        mediaRecorder.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioURL(audioUrl);
          audioChunks.current = [];
          uploadAudio(audioBlob);
        };
      })
      .catch((error) => console.error("Error accessing audio devices.", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      props.recording(false);
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    }
  };

  const uploadAudio = (blob) => {
    setLoadingTranscription(true);
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");
    if (props.dataAssistant.speechace_enabled === true) {
      formData.append(
        "speechace_enabled",
        props.dataAssistant.speechace_enabled
      );
    }
    try {
      axios
        .post(process.env.REACT_APP_URL_AUDIO, formData, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {

          setLoadingTranscription(false);
          if (props.dataAssistant.speechace_enabled) {
            
            props.dataS(res.data.speechace_feedback_response.text_score)
            props.sendMessage(res.data.transcription, res.data.speechace_feedback_response);
          } else {
            props.transcription(res.data.transcription);
          }
        })
        .catch((err) => {
          setLoadingTranscription(false);
          if (err.response.data.detail === "User is inactive") {
            showModalInactive();
          }
        });
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");

    const draw = () => {
      requestAnimationFrame(draw);

      if (!analyserRef.current) return;

      analyserRef.current.getByteFrequencyData(dataArrayRef.current);

      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / dataArrayRef.current.length) * 2;
      let barHeight;
      let x = canvas.width / 2;

      for (let i = 0; i < dataArrayRef.current.length; i++) {
        barHeight = (dataArrayRef.current[i] / 255) * canvas.height;

        canvasContext.fillStyle = "#1A73E8";
        canvasContext.beginPath();
        canvasContext.moveTo(x, canvas.height);
        canvasContext.lineTo(x, canvas.height - barHeight);
        canvasContext.arcTo(
          x + barWidth,
          canvas.height - barHeight,
          x + barWidth,
          canvas.height,
          8
        );
        canvasContext.lineTo(x + barWidth, canvas.height);
        canvasContext.closePath();
        canvasContext.fill();

        x -= barWidth + 1;
      }

      x = canvas.width / 2 + barWidth;

      for (let i = 0; i < dataArrayRef.current.length; i++) {
        barHeight = (dataArrayRef.current[i] / 255) * canvas.height;

        canvasContext.fillStyle = "#1A73E8";
        canvasContext.beginPath();
        canvasContext.moveTo(x, canvas.height);
        canvasContext.lineTo(x, canvas.height - barHeight);
        canvasContext.arcTo(
          x + barWidth,
          canvas.height - barHeight,
          x + barWidth,
          canvas.height,
          8
        );
        canvasContext.lineTo(x + barWidth, canvas.height);
        canvasContext.closePath();
        canvasContext.fill();

        x += barWidth + 1;
      }
    };

    draw();
  }, []);

  return (
    <div className="relative">
      {loadingTranscription ? (
        <button className="p-2 rounded-full border-[1px] border-bunker-600 dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer">
          <span className="loading loading-spinner loading-xs"></span>
        </button>
      ) : recording ? (
        <button
          onClick={stopRecording}
          className="p-2 rounded-full border-[1px] border-bunker-600 dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
        >
          <FaStop className="text-bunker-600 dark:text-[#7d7d7d] text-xl" />
        </button>
      ) : (
        <button
          id="mic"
          onClick={startRecording}
          className="p-2 rounded-full border-[1px] border-bunker-600 dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
        >
          <FaMicrophone className="text-bunker-600 dark:text-[#7d7d7d] text-xl" />
        </button>
      )}
      <canvas
        ref={canvasRef}
        width="100%"
        height="20%"
        className="absolute top-1/2 -translate-y-1/2 left-[120%] rounded-full"
      ></canvas>
    </div>
  );
};

export default Mic;
