import React from 'react'

const CardActivityHomeSkeleton = () => {
  return (
    <div className="h-[146px] w-[300px] p-4 flex gap-4 bg-[#202024] dark:bg-[#f9f9f9] rounded-xl  custom-transition">
      <div className="skeleton bg-[#26272B]  w-[30%] h-full rounded-xl"></div>
      <div className="w-[70%] h-full flex flex-col justify-between">
        <div className="skeleton bg-[#26272B]  h-3 w-full"></div>
        <div className="skeleton bg-[#26272B]  h-3 w-10"></div>
        <div className="skeleton bg-[#26272B]  h-3 w-40"></div>
        <div className="skeleton bg-[#26272B]  h-3 w-40"></div>
        <div className="skeleton bg-[#26272B]  h-3 w-28"></div>
      </div>
    </div>
  )
}

export default CardActivityHomeSkeleton