import { useAuth0 } from '@auth0/auth0-react'
import React, { useContext } from 'react'
import { UserContext } from '../utils/userContext'
import { t } from 'i18next'

const ProfileSettings = () => {
    const {user, isAuthenticated} = useAuth0()
    const {user: userBack} = useContext(UserContext)

  return (
    <div className='flex flex-col'>
      <h1 className="text-bunker-500 text-xl mb-10 font-medium">{t('profile')}</h1>
      <div className='w-full flex flex-col gap-4'>
        <img src={isAuthenticated && user.picture} alt="user" className='w-[86px] h-[86px] rounded-full' />
        <div className='flex-col relative border rounded-spacing-s py-4 text-md file:border-0 file:bg-transparent file:text-md file:font-medium placeholder:text-placeholder outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 appearance-none px-3  pb-[15px] pt-[23px] placeholder:text-md bg-pr-800  border-bunker-800 custom-transition hover:border-bunker-600 focus-visible:border-[#fafafa] rounded-xl'>
            <label htmlFor="name" className='absolute top-2 left-3 text-xs text-bunker-50'>{t('name')}</label>
        <input type="text" id="name" value={isAuthenticated && userBack?.full_name} className='w-full outline-none bg-transparent text-bunker-50 text-sm' />

        </div>
        <div className='flex-col relative border rounded-spacing-s py-4 text-md file:border-0 file:bg-transparent file:text-md file:font-medium placeholder:text-placeholder outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 appearance-none px-3  pb-[15px] pt-[23px] placeholder:text-md bg-pr-800  border-bunker-800 custom-transition hover:border-bunker-600 focus-visible:border-[#fafafa] rounded-xl'>
            <label htmlFor="email" className='absolute top-2 left-3 text-xs text-bunker-50'>{t('email')}</label>
        <input type="text" id="email" value={ isAuthenticated && userBack?.email} className='w-full outline-none bg-transparent text-bunker-50 text-sm' />

        </div>
      </div>
    </div>
  )
}

export default ProfileSettings
