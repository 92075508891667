import { t } from 'i18next'
import React from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'

const FormUploadCVSettings = (props) => {

  return (
    <form
      onSubmit={props.handleSubmit}
      className="flex flex-col gap-5 items-center justify-center w-full"
    >
      <label
        htmlFor="dropzone-file2"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-bunker-800 border-dashed rounded-lg cursor-pointer bg-main2 dark:hover:bg-bray-800 hover:bg-pr-700 custom-transition  "
        onDragOver={props.handleDragOver}
        onDrop={props.handleDrop}
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            className="w-10 h-10 mb-3 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          <p className="mb-2 text-sm  dark:text-gray-400">
            <span className="font-semibold">{t('click-to-upload')}</span> {t('or-drag-and-drop')}
          </p>
          <p className="text-xs  dark:text-gray-400">
            PDF (MAX. 2MB)
          </p>
          <p className="text-xs font-semibold  dark:text-gray-400">
            {props.fileName}
          </p>
        </div>
        <input
          id="dropzone-file2"
          type="file"
          accept=".pdf"
          className="hidden"
          onChange={props.handleResume}
        />
      </label>
      {props.error && <p className="text-red-500">{props.error}</p>}
      <button
        type="submit"
        className="submit2 hidden justify-center items-center gap-3 rounded hover:bg-[#202024] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-bunker-50 custom-transition"
      >
        {t('upload')}{" "}
        {props.loading ? (
          <span className="loading loading-spinner loading-sm"></span>
        ) : (
          <AiOutlineCloudUpload />
        )}
      </button>
    </form>
  )
}

export default FormUploadCVSettings
