import React, { useState } from "react";
import MenuSettings from "../UI/MenuSettings";
import ProfileSettings from "./ProfileSettings";
import AccountSettings from "./AccountSettings";
import VerticalSettings from "./VerticalSettings";
import { IoClose } from "react-icons/io5";
import AboutMeSettings from "./AboutMeSettings";
import LevelEnglishSettings from "./LevelEnglishSettings";
import { HiOutlineMenuAlt1 } from "react-icons/hi";

const OverlaySettings = () => {
  const [selectedLayout, setSelectedLayout] = useState("about me");
  const [sideStatus, setSideStatus] = useState();

  const renderLayout = () => {
    switch (selectedLayout) {
      case "about me":
        return <AboutMeSettings />;
      case "level":
        return <LevelEnglishSettings />;
      case "profile":
        return <ProfileSettings />;
      case "account":
        return <AccountSettings />;
      case "vertical":
        return <VerticalSettings />;
      default:
        return <AboutMeSettings />;
    }
  };

  const hideOverlaySettigns = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("fixed");
    overlay.classList.add("hidden");
  };

  const showMenuSettings = () => {
    const menuSettings = document.querySelector('.menu-settings')
    menuSettings.classList.add("translate-x-0")
    menuSettings.classList.remove("-translate-x-full")
    menuSettings.classList.add("w-[70%]")
    menuSettings.classList.remove("w-0")


    setSideStatus("show");
  };

  const hideMenuSettings = () =>{
    const menuSettings = document.querySelector('.menu-settings')
    menuSettings.classList.remove("translate-x-0")
    menuSettings.classList.add("-translate-x-full")
    menuSettings.classList.remove("w-[50%]")
    menuSettings.classList.add("w-0")


    setSideStatus("hide");
  }

  return (
    <div className="overlay w-full h-screen justify-center items-center hidden z-50 left-0 top-0 bottom-0 right-0">
      
      <div className="h-full w-full flex justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10">
        <div className="h-full w-full md:h-[624px] md:w-[708px] flex bg-pr-600 rounded-none md:rounded-xl relative overflow-hidden">
        {sideStatus === "hide" && (
            <button className="flex md:hidden justify-center items-center fixed left-2 top-2">
              <div
                onClick={showMenuSettings}
                className="group p-2 rounded-full hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9]  custom-transition flex justify-center items-center cursor-pointer "
              >
                <HiOutlineMenuAlt1 className="text-[#94949D] dark:text-[#7d7d7d] dark:group-hover:text-[#94949D]  text-2xl" />
              </div>
            </button>
          )}
          <div className="bg-pr-800 h-full menu-settings translate-x-0 absolute md:static md:translate-x-0 min-h-16 md:h-full mt-0 flex flex-col justify-start md:justify-between items-center p-5 md:pt-8 w-[70%] md:w-[35%] overflow-hidden border-b-pr-200 border-b-[1px] md:border-b-none md:border-b-0 md:border-r-[1px] md:border-r-pr-200 custom-transition z-20 md:z-0">
            <MenuSettings
              selectedLayout={selectedLayout}
              setSelectedLayout={setSelectedLayout}
              hideMenu={hideMenuSettings}
            />
          </div>
          <div className="w-full md:w-[70%] h-full px-4 md:px-6 pt-0 md:pt-8 mt-14 md:mt-0 relative">
            {renderLayout()}
          </div>
          <IoClose
            onClick={hideOverlaySettigns}
            className="absolute top-4 right-4 text-lg cursor-pointer z-50"
          />
        </div>
      </div>
    </div>
  );
};

export default OverlaySettings;
