import React, { useEffect, useState } from "react";
import { CiCloudMoon, CiSun } from "react-icons/ci";

const Theme = (props) => {
  const [theme, setTheme] = useState("dark");

  const changeTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    const sun = document.querySelector(".sun");
    const moon = document.querySelector(".moon");

    if (theme === "light") {
      document.querySelector("html").classList.add("dark");
      sun.classList.add("opacity-0");
      sun.classList.remove("opacity-100");

      moon.classList.add("opacity-100");
      moon.classList.remove("opacity-0");
    } else {
      document.querySelector("html").classList.remove("dark");
      sun.classList.remove("opacity-0");
      sun.classList.add("opacity-100");

      moon.classList.remove("opacity-100");
      moon.classList.add("opacity-0");
    }
  }, [theme]);

  return (
    <button
      onClick={changeTheme}
      className={`${props.position && "absolute bottom-5 right-5"} group realtive p-2 rounded-full border-[1px] border-[#29292E] dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#F9F9F9] custom-transition hidden md:flex justify-center items-center  `}
    >
      <CiSun className="sun text-[#fafafa] dark:text-[#7d7d7d]  text-xl opacity-100 custom-transition absolute" />
      <CiCloudMoon className="moon text-[#fafafa] dark:text-[#7d7d7d]  text-xl opacity-0 custom-transition" />
    </button>
  );
};

export default Theme;
