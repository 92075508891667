import React from 'react'

const CardSection1AssistantHomeSkeleton = () => {
  return (
    <div className="p-4 flex flex-col items-start justify-between  w-[40%] h-full bg-[#202024] dark:bg-[#f9f9f9] rounded-2xl">
      <div className="flex flex-col gap-2 w-full h-full">
        <div className="w-full flex justify-start items-start gap-2">
          <div className="skeleton bg-[#26272B] w-[48px] h-[48px] rounded-full"></div>
          <div className="skeleton bg-[#26272B] h-3 w-full"></div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <div className="skeleton bg-[#26272B] h-3 w-full"></div>
          <div className="skeleton bg-[#26272B] h-3 w-full"></div>
        </div>
      </div>
    </div>
  )
}

export default CardSection1AssistantHomeSkeleton