import { useMaybeRoomContext } from '@livekit/components-react';
import { RoomEvent } from 'livekit-client';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { IoClose } from 'react-icons/io5';
import { UserContext } from '../utils/userContext';

const TranscriptionComponent = (props) => {
    const room = useMaybeRoomContext();
    const [transcriptions, setTranscriptions] = useState({});
    const containerRef = useRef(null);
    const {user} = useContext(UserContext);

    const hideSide = () => {
      props.setIsSideOpen(false);
      props.sideState("hide");
    };

    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, [transcriptions]);
  
    useEffect(() => {
      if (!room || !user) {
        return;
      }
  
  
      const updateTranscriptions = (segments, participant, publication) => {
        
        setTranscriptions((prev) => {
          const newTranscriptions = { ...prev };
          for (const segment of segments) {
            newTranscriptions[segment.id] = {
              ...segment,
              origin: participant.isLocal ? user?.full_name : 'Nina', // Diferenciar origen
            };
          }
          return newTranscriptions;
        });
      };
  
      room.on(RoomEvent.TranscriptionReceived, updateTranscriptions);
      return () => {
        room.off(RoomEvent.TranscriptionReceived, updateTranscriptions);
      };
    }, [room, user]);
  
    return (
      <div ref={containerRef} className={`${
        props.isSideOpen
          ? "translate-x-0 max-w-80 p-2 py-3 border-l"
          : "-translate-x-full max-w-0 p-0"
      } bg-pr-800 h-[91vh] w-full pb-20 md:pb-2  border-pr-400  custom-transition z-[51] md:z-[30] absolute md:relative overflow-hidden overflow-y-scroll flex flex-col gap-10`}>
        {props.isSideOpen && <IoClose onClick={hideSide} className='text-white text-xl fixed right-2 top-2 cursor-pointer'/>}
        <h1 className='text-center font-semibold text-white uppercase mt-5'>Transcriptions</h1>
        {/* <pre>{JSON.stringify(transcriptions, null, 2)}</pre> */}
        <div className='flex flex-col gap-2'>
          {/* {Object.values(transcriptions).map((transcription) => (
            <div key={transcription.id} className='bg-bunker-700 p-2 rounded-md'>
              <p className='text-white'>{transcription.text}</p>
            </div>
          ))} */}
          {Object.values(transcriptions)
      .sort((a, b) => a.firstReceivedTime - b.firstReceivedTime)
      .map((segment) => (
        <div className={`flex flex-col w-full gap-1`}>
          <span className={`${
          segment.origin !== 'Nina' ? 'text-end' : 'text-start'
        } text-xs w-auto`}>{segment.origin}</span>
        <div
          key={segment.id}
          className={` relative p-2 rounded-md flex w-auto max-w-[80%] ${
            segment.origin !== 'Nina' ? 'bg-pr-200 justify-end ml-auto ' : 'bg-blue-700 justify-start mr-auto'
          }`}
        >
          <p className="text-white">{segment.text}</p> 
        </div>
        </div>
      ))}
          </div>
      </div>
    );

  };

export default TranscriptionComponent