import React, { useEffect, useState } from "react";
import "../../assets/css/loader.css";

// const messages = [
//   `${t('we-are-checking-your-cv-please-wait')}`,
//   `${t('we-have-found-some-teachers-that-match-you')}`,
//   `${t('we-are-creating-a-custom-dashboard-for-you')}`,
// ];

const messages = [
  `We are checking your cv please wait`,
  `We have found some teachers that match you`,
  `We are creating a custom dashboard for you`,
];

const SplashCV = (props) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [show, setShow] = useState(true);

  const showOverlaySettigns = () =>{
    const overlay = document.querySelector('.overlay')
    overlay.classList.remove("hidden")
    overlay.classList.add("fixed")
  }

  // const hideSplash = () =>{
  //   const splash = document.querySelector(".overlay3")

  //   splash.classList.remove("fixed")
  //   splash.classList.add("hidden")

  // }

  useEffect(() => {
    let interval;

    if (props.loading) {
      interval = setInterval(() => {
        setShow(false);
        setTimeout(() => {
          setCurrentMessageIndex((prevIndex) =>
            prevIndex === messages.length - 1 ? 0 : prevIndex + 1
          );
          setShow(true);
        }, 500); // breve pausa antes de mostrar el siguiente mensaje
      }, 3000); // tiempo que se muestra cada mensaje
    } else {
      setCurrentMessageIndex(messages.length); // Set index to the last to show "Done!"
      setShow(true);
      showOverlaySettigns(); // Assuming you want to show overlay settings when done
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [props.loading]);

  const finalMessage = "Done!";
  

  return (
    <div className="overlay3 w-full h-screen justify-center bg-main items-center fixed z-[100] left-0 top-0 bottom-0 right-0 custom-transition">
      <div className="w-full h-full flex flex-col justify-around items-center ">
        <div
          className={`text-4xl text-bunker-50 text-center transition-opacity duration-500 ${
            show ? "opacity-100" : "opacity-0"
          }`}
        >
          {props.loading ? messages[currentMessageIndex] : finalMessage}
          </div>
        <div class="typewriter">
          <div class="slide">
            <i></i>
          </div>
          <div class="paper"></div>
          <div class="keyboard"></div>
        </div>
      </div>
    </div>
  );
};

export default SplashCV;
