import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { UserContext } from "../utils/userContext";
import { useTranslation } from "react-i18next";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init("f8358a3b2025d29fe2822013871b15f1");

const HeaderHome = (props) => {
  const { loginWithRedirect, isAuthenticated, getIdTokenClaims, user } =
    useAuth0();
  const {
    setUser,
    setAuthorized,
    authorized,
    user: userBack,
  } = useContext(UserContext);

  const { t, i18n } = useTranslation();

  const showModalInactive = () => {
    const modalI = document.querySelector(".modalI");
    modalI.classList.add("flex");
    modalI.classList.remove("hidden");
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  const showSide = () => {
    if (authorized) {
      props.setIsSideOpen(true);
      props.showSide("show");
    }
  };

  useEffect(() => {
    const obtenerToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getIdTokenClaims();

          let data = {
            auth_token: token.__raw,
          };
          axios
            .post(process.env.REACT_APP_AUTH0, data, {
              headers: {
                "X-API-KEY": process.env.REACT_APP_X_APIKEY,
              },
            })
            .then((res) => {
              window.localStorage.setItem("token_user", res.data.tokens.access);
              window.localStorage.setItem(
                "refresh_token_user",
                res.data.tokens.refresh
              );
              setUser(res.data.user);
              amplitude.setUserId(res.data.user.email);
              amplitude.track("El usuario inicia sesión", {
                userId: res.data.user.id,
                userEmail: res.data.user.email,
                userName: res.data.user.full_name,
                buttonName: "LogIn",
                page: "Home",
              });
              setAuthorized(true);
            })
            .catch((err) => {
              if (err.response?.data?.detail === "User account is inactive.") {
                showModalInactive();
              }
            });
        } catch (error) {
          console.error("Error al obtener el token:", error);
        }
      }
    };

    obtenerToken();
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    if (authorized) {
      if (
        userBack.english_level === "Basic" ||
        userBack.english_level === "Intermediate"
      ) {
        i18n.changeLanguage("es");
      } else if (userBack.english_level === "Advanced") {
        i18n.changeLanguage("en");
      }
    }
  }, [userBack?.english_level, authorized]);

  return (
    <div className="max-w-7xl w-full h-auto flex justify-end items-start md:justify-between px-8 pr-0 pb-2 bg-main2 dark:bg-white relative ">
      {!props.isSideOpen && authorized && (
        <button className="flex justify-center items-center fixed left-5 top-5">
          <div
            onClick={showSide}
            className="group p-2 rounded-full hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9]  custom-transition flex justify-center items-center cursor-pointer "
          >
            <HiOutlineMenuAlt1 className="text-bunker-800 dark:text-[#7d7d7d] dark:group-hover:text-[#94949D]  text-2xl" />
          </div>
        </button>
      )}
      {isAuthenticated ? (
        <div className="w-full md:w-auto flex flex-col items-center justify-center md:items-start gap-2">
          <p className="text-base text-[#A2A2AC] ">{t("welcome")}</p>
          <div className="flex items-center justify-center gap-2 w-auto">
            <div className="w-[20px] h-[20px] rounded-full bg-violet-500 flex justify-center items-center overflow-hidden">
              {/* <p className="text-xs text-bunker-50">S</p> */}
              <img
                src={user?.picture}
                alt="user"
                className="w-full h-full object-cover"
              />
            </div>
            <p className="text-sm w-auto line-clamp-1 md:line-clamp-none text-center  md:text-lg text-bunker-50 dark:text-[#7D7D7D]">
              {userBack?.full_name}
            </p>
          </div>
        </div>
      ) : (
        <div className="w-full md:w-auto flex justify-center gap-8 items-center">
          <div>
            <h1 className="text-base text-white dark:text-[#7D7D7D] font-bold">
              English<span className="text-bunker-600">Code.ai</span>
            </h1>
          </div>
          <div className="w-auto flex gap-2 items-center">
            <button
              onClick={() => {
                loginWithRedirect();
              }}
              className="custom-transition bg-bunker-600 rounded-full text-white hover:text-black hover:bg-[#e4e4e4] px-4 text-sm font-normal py-2"
            >
              {t("sign-in")}
            </button>
          </div>
        </div>
      )}

      {/* <div className=" hidden md:flex gap-2 w-full max-w-96 text-bunker-50 dark:text-[#7D7D7D] border-spacing-1 border-divider flex-row self-center items-center rounded-spacing-xs bg-[#202024] dark:bg-white dark:border-[#202024] p-4 pl-5 placeholder:text-[#7C7C87] rounded-full">
        <button className=" flex justify-center items-center">
          <FiSearch className="text-bunker-50 dark:text-[#7D7D7D]" />
        </button>
        <input
          type="text"
          placeholder="Search Activity"
          className="outline-none border-none w-[90%] bg-transparent text-bunker-50 text-sm dark:text-[#7D7D7D] text-sm"
        />
      </div> */}
    </div>
  );
};

export default HeaderHome;
