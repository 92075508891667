import React, { createContext, useState } from 'react';

// Crear el contexto
export const UserContext = createContext();

// Crear el proveedor del contexto
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authorized, setAuthorized] = useState(false)

    return (
        <UserContext.Provider value={{ user, setUser, setAuthorized, authorized }}>
            {children}
        </UserContext.Provider>
    );
};