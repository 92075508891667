import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserProvider } from './components/utils/userContext';
import './i18n';


const domain = process.env.REACT_APP_AUTH0_DOMAIN
const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Auth0Provider domain={domain} clientId={client_id} authorizationParams={{
      redirect_uri: window.location.origin
    }}>
    
    <UserProvider>
    <App />
    </UserProvider>
    </Auth0Provider>

  </React.StrictMode>
);

reportWebVitals();
