import { t } from "i18next";
import React from "react";
import { FaHourglassEnd } from "react-icons/fa";

const LevelAdvancedEnglish = () => {
  return (
    <div className="w-full flex flex-col justify-center items-start  gap-10  pb-2">
      {/* <h2 className="text-bunker-50 text-base">You have <strong>ADVANCED</strong> English <strong>B2/C1</strong></h2>
      <p className="text-xs">
      You communicate in English with different accents, but you feel blocked when speaking or in interviews
      </p> */}

      <div className="w-full flex flex-col justify-center items-center mt-2">
        <div className="relative w-[90%] rounded-lg border border-bunker-800 flex flex-col justify-center items-center  p-4 pt-3">
          <h2 className="absolute left-1/2 -translate-x-1/2 -top-5 text-bunker-50 font-bold text-base text-center bg-pr-600 p-2">
            {t('avanzado')}
          </h2>
          <FaHourglassEnd className="text-xl text-bunker-600 text-center my-5" />

          <p className="text-xs text-center">
            {t('avanzado-1')}
          </p>
        </div>
        <div className="w-full h-[150px] flex flex-col justify-center items-center">
          <span className="w-px h-full bg-bunker-600"></span>
          <span className="w-px h-full bg-bunker-600"></span>
        </div>
        <div className="relative w-[90%] rounded-lg border border-bunker-800 flex flex-col justify-center items-center p-4 pt-3">
          <h2 className="absolute left-1/2 -translate-x-1/2 -top-5 text-bunker-50 font-bold text-base text-center bg-pr-600 p-2">
            {t('final')}
          </h2>
          <FaHourglassEnd className="text-xl text-bunker-600 text-center my-5" />

          <p className="text-xs text-center">
            {t('avanzado-2')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LevelAdvancedEnglish;
