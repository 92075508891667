import { t } from 'i18next'
import React from 'react'

const ModalActivityCompleted = () => {
  return (
    <div className="absolute z-[100] modalActivity w-auto h-auto rounded-lg p-4 bg-[#63985E] flex justify-normalc items-center top-0 left-1/2 -translate-y-full -translate-x-1/2 custom-transition">
      <p className="text-sm text-white font-bold text-center">{t('you-have-already-completed-all-the-activities-of-this-type')}</p>
    </div>
  )
}

export default ModalActivityCompleted
