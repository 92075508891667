import { format, isToday } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../utils/userContext";
import axios from "axios";
import DatePicker from "react-datepicker";
// import "../../assets/css/datepicker-custom.css"
import "react-datepicker/dist/react-datepicker.css";
import { fromZonedTime, toZonedTime } from 'date-fns-tz';


const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const generateTimeOptions = (minHour = 0, minMinute = 0) => {
  const options = [];
  for (let hour = minHour; hour < 24; hour++) {
    for (let minute = hour === minHour ? minMinute : 0; minute < 60; minute += 20) {
      const time = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      options.push({ value: time, label: time });
    }
  }
  return options;
};

const ModalAgended = (props) => {
  const token = window.localStorage.getItem("token_user");

  const [message, setMessage] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);

  const { user } = useContext(UserContext);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDate || !selectedTime) {
      setMessage("Por favor selecciona una fecha y hora.");
      return;
    }

    // Combina la fecha y la hora seleccionada por el usuario
    const [hours, minutes] = selectedTime.split(':');
    const localDateTime = new Date(selectedDate);
    localDateTime.setHours(hours, minutes);
    
    // Convierte la fecha y hora seleccionada a UTC antes de enviarla al backend
    const utcDateTime = fromZonedTime(localDateTime, userTimeZone);
    
    // Formatear la fecha en UTC para el backend
    const scheduledStartTime = format(utcDateTime, 'yyyy-MM-dd HH:mm:ss');
    
    const schema = {
      assistant_fk: props.assistantId,
      scheduled_start_time: scheduledStartTime,
      user_fk: user.id,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_URL_AGENDED_SESSION,
        schema,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            Authorization: "Bearer " + token,
          },
        }
      );

    } catch (error) {
      console.error("Error al conectar con el backend:", error);
    }
  };

  useEffect(() => {
    const now = new Date();
    const zonedNow = toZonedTime(now, userTimeZone); // Convierte UTC a la zona horaria del cliente

    if (selectedDate && isToday(selectedDate)) {
      setTimeOptions(generateTimeOptions(zonedNow.getHours(), zonedNow.getMinutes()));
    } else {
      setTimeOptions(generateTimeOptions());
    }
  }, [selectedDate]);

  return (
    <div className="relative rounded-lg bg-pr-900 flex flex-col justify-between items-center w-[90%] md:w-[30%] p-10 gap-10">
      <form
        className="w-full flex flex-col justify-center items-center gap-10"
      >
        <h1 className="text-base md:text-xl font-bold text-bunker-600 text-center">
          Agenda tu sesion ahora!
        </h1>
        <div className="flex w-full justify-center items-center gap-4">
          <div>
          <label className="block mb-2 font-medium ">Fecha:</label>
          <DatePicker
            selected={selectedDate}
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
            onChange={(date) => setSelectedDate(date)}
            placeholderText="Selecciona una fecha"
            className="w-full border rounded-spacing-s text-md placeholder:text-placeholder focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 appearance-none px-3  py-4 placeholder:text-md bg-pr-800  border-bunker-800 custom-transition hover:border-bunker-600 focus-visible:border-[#fafafa] rounded-xl outline-none"
          />
          </div>
          <div>
          <label className="block mb-2 font-medium ">Hora:</label>
          {/* <Select
            options={timeOptions}
            onChange={setSelectedTime}
            placeholder="Selecciona la hora"
            className="w-full"
            classNamePrefix="react-select"
          /> */}
          <select
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            className="w-full border rounded-spacing-s text-md placeholder:text-placeholder focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 appearance-none px-3  py-4 placeholder:text-md bg-pr-800  border-bunker-800 custom-transition hover:border-bunker-600 focus-visible:border-[#fafafa] rounded-xl outline-none"
          >
            <option value="" disabled>
              Selecciona la hora
            </option>
            {timeOptions.map((time, index) => (
              <option key={index} value={time.value}>
                {time.label}
              </option>
            ))}
          </select>
        </div>
        </div>
        
        
        <button
          onClick={handleSubmit}
          className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600"
        >
          Agendar Reunión
        </button>
      </form>
    </div>
  );
};

export default ModalAgended;
