import React from 'react'
import { MdMissedVideoCall } from 'react-icons/md'
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('f8358a3b2025d29fe2822013871b15f1');

const VideoCall = (props) => {


  return (
    <div>
      {
        props.videoCallActive === false && <button onClick={()=>{
            props.setVideoCallActive(true)
            props.mode("video_call")
            props.setVideo(null)
          }} id="videocall" className=" p-2 rounded-full border-[1px] border-bunker-600 dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer mr-2">
            <MdMissedVideoCall className="text-bunker-600 dark:text-[#7d7d7d] text-xl" />
          </button>
      }
    </div>
  )
}

export default VideoCall