import { t } from "i18next";
import React, { useEffect} from "react";
import { IoMdSend } from "react-icons/io";

const InputChat = (props) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Evita que se envíe el formulario si está dentro de un form
      props.send(); // Llama a la función sendMessage
    }
  };

  useEffect(() => {
    if (props.transcription) {
      props.setInput(props.transcription);
    }
  }, [props.transcription]);

  return (
    <div className="flex grow w-full items-end p-1 m-4 mr-2  bg-pr-800 dark:bg-[#f9f9f9] border-[1px] border-bunker-600 dark:border-none rounded-full">
      <div className="w-full relative flex flex-col ml-2">
        <textarea
          name=""
          id="input"
          disabled
          placeholder={t('quality-assurance-message')}
          className="flex px-3 w-full border placeholder:text-xs md:placeholder:text-base text-bunker-50 dark:text-black placeholder:text-[#7C7C87] text-sm md:text-base file:border-0 file:bg-transparent file:text-md file:font-medium disabled:cursor-not-allowed disabled:opacity-50 resize-none focus-visible:outline-none border-input h-10 py-2 max-h-96  border-none bg-pr-800 dark:bg-[#f9f9f9] placeholder:text-placeholder placeholder:overflow-hidden placeholder:whitespace-nowrap"
          value={props.input}
          onChange={(e) => props.setInput(e.target.value)}
          onKeyDown={handleKeyDown}
        ></textarea>
        <span className="flex flex-row px-3 gap-2 text-sm"></span>
      </div>
      <div className="flex flex-row gap-2 text-sm">
        <button onClick={() => props.send()} className="flex justify-center items-center w-[40px] h-[40px] bg-white dark:bg-[#7d7d7d] rounded-full pl-1 hover:bg-[#E5E5E5] custom-transition">
          <IoMdSend className="text-[#26272B] dark:text-bunker-50 text-xl " />
        </button>
      </div>
    </div>
  );
};

export default InputChat;
