"use client";

import { AnimatePresence, motion } from "framer-motion";
import {
  LiveKitRoom,
  useVoiceAssistant,
  BarVisualizer,
  RoomAudioRenderer,
  VoiceAssistantControlBar,
  DisconnectButton,
  useMaybeRoomContext,
} from "@livekit/components-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useKrispNoiseFilter } from "@livekit/components-react/krisp";
import { AccessToken } from "livekit-server-sdk";
import { NoAgentNotification } from "../UI/NoAgentNotification";
import { CloseIcon } from "../UI/CloseIcon";
import "@livekit/components-styles";
import Logo from "../../assets/img/Logo1.png";
import { UserContext } from "../utils/userContext";
import { useAuth0 } from "@auth0/auth0-react";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import { t } from "i18next";
import { RoomEvent } from "livekit-client";
import Timer from "../UI/Timer";
import { set } from "date-fns";
import TranscriptionComponent from "../UI/TranscriptionComponent";
import { CgTranscript } from "react-icons/cg";

amplitude.init("f8358a3b2025d29fe2822013871b15f1");


const API_KEY = process.env.REACT_APP_LIVEKIT_API_KEY;
const API_SECRET = process.env.REACT_APP_LIVEKIT_API_SECRET;
const LIVEKIT_URL = process.env.REACT_APP_LIVEKIT_URL;

export default function CallLiveKit() {
  const [connectionDetails, updateConnectionDetails] = useState({
    serverUrl: "",
    roomName: "",
    participantName: "",
    participantToken: "",
  });
  const [agentState, setAgentState] = useState("disconnected");
  const { user, authorized, setAuthorized, setUser } = useContext(UserContext);
  const { loginWithRedirect, isAuthenticated, getIdTokenClaims } = useAuth0();
  const token = window.localStorage.getItem("token_user")
  const [showTimer, setShowTimer] = useState(false)
  const [loadPicture2, setLoadPicture2] = useState(false)
  const [sideState, setSideState] = useState("show");
  const [isSideOpen, setIsSideOpen] = useState(false); 
  
  const onConnectButtonClicked = useCallback(async () => {  
    
    if(!isAuthenticated){
      loginWithRedirect({
        redirectUri: window.location.origin+"/live"
      })
    }else{
      setShowTimer(true)
    try {
      if (LIVEKIT_URL === undefined) {
        throw new Error("LIVEKIT_URL is not defined");
      }
      if (API_KEY === undefined) {
        throw new Error("LIVEKIT_API_KEY is not defined");
      }
      if (API_SECRET === undefined) {
        throw new Error("LIVEKIT_API_SECRET is not defined");
      }

      // Generate participant token
      const participantIdentity = `voice_assistant_user_${Math.floor(
        Math.random() * 10_000
      )}`;
      const roomName = `voice_assistant_room_${Math.floor(
        Math.random() * 10_000
      )}`;
      const participantToken = await createParticipantToken(
        { identity: user.id?.toString(), metadata: JSON.stringify({user_token: token}) },
        roomName
      );

      // Return connection details
      const data = {
        serverUrl: LIVEKIT_URL,
        roomName,
        participantToken: participantToken,
        participantName: participantIdentity,
      };
      updateConnectionDetails(data);

      //   return NextResponse.json(data);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        // setError(error.message);
      }
    }
    }
  }, [isAuthenticated, user]);

  function createParticipantToken(userInfo, roomName) {
    const at = new AccessToken(API_KEY, API_SECRET, {
      ...userInfo,
      ttl: "15m",
    });
    const grant = {
      room: roomName,
      roomJoin: true,
      canPublish: true,
      canPublishData: true,
      canSubscribe: true,
    };
    at.addGrant(grant);
    return at.toJwt();
  }

  const showModalInactive = () => {
    const modalI = document.querySelector(".modalI");
    modalI.classList.add("flex");
    modalI.classList.remove("hidden");
  };

  useEffect(() => {
    console.log(isAuthenticated)
    const obtenerToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getIdTokenClaims();

          let data = {
            auth_token: token.__raw,
          };
          axios
            .post(process.env.REACT_APP_AUTH0, data, {
              headers: {
                "X-API-KEY": process.env.REACT_APP_X_APIKEY,
              },
            })
            .then((res) => {
              window.localStorage.setItem("token_user", res.data.tokens.access);
              window.localStorage.setItem(
                "refresh_token_user",
                res.data.tokens.refresh
              );
              setUser(res.data.user);
              amplitude.setUserId(res.data.user.email);
              amplitude.track("El usuario inicia sesión", {
                userId: res.data.user.id,
                userEmail: res.data.user.email,
                userName: res.data.user.full_name,
                buttonName: "LogIn",
                page: "Home",
              });
              setAuthorized(true);
            })
            .catch((err) => {
              if (err.response?.data?.detail === "User account is inactive.") {
                showModalInactive();
              }
            });
        } catch (error) {
          console.error("Error al obtener el token:", error);
        }
      }
    };

    obtenerToken();
  }, [isAuthenticated, getIdTokenClaims]);


  return (
    <main
      data-lk-theme="default"
      className="h-screen flex flex-col justify-between bg-[var(--lk-bg)] !overflow-hidden	"
    >
      <img
        src={Logo}
        className="absolute right-4 top-0 z-[29] md:top-2  w-[100px] h-[50px] md:w-[200px] md:h-[100px]"
        alt=""
      />
      <LiveKitRoom
        token={connectionDetails?.participantToken}
        serverUrl={connectionDetails?.serverUrl}
        connect={connectionDetails !== undefined}
        audio={true}
        video={false}
        onMediaDeviceFailure={onDeviceFailure}
        onDisconnected={() => {
          updateConnectionDetails(undefined);
        }}
        className="flex justify-between items-start !bg-pr-600 h-screen"
      >
        <div className="w-full flex flex-col justify-between items-center !bg-pr-600 h-screen">
        <SimpleVoiceAssistant onStateChange={setAgentState} setLoadPicture2={setLoadPicture2}/>
        <ControlBar
          onConnectButtonClicked={onConnectButtonClicked}
          agentState={agentState}
          showTimer={showTimer}
          setShowTimer={setShowTimer}
          loadPicture={loadPicture2}
          setShowSide={setSideState}
          showSide={sideState}
          setIsSideOpen={setIsSideOpen}
          isSideOpen={isSideOpen}
        />
        {/* <ChatToggle /> */}
        <RoomAudioRenderer />
        <NoAgentNotification state={agentState} />
        </div>
        <TranscriptionComponent sideState={setSideState} setIsSideOpen={setIsSideOpen} isSideOpen={isSideOpen}/>

      </LiveKitRoom>
    </main>
  );
}

function SimpleVoiceAssistant({ onStateChange, setLoadPicture2 }) {
  const { state, audioTrack } = useVoiceAssistant();
  const [assistant, setAssistant] = useState([])
  const { user, authorized } = useContext(UserContext);
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [loadPicture, setLoadPicture] = useState(false)
  

  useEffect(() => {
    onStateChange(state);
  }, [onStateChange, state]);
  let numberPoints = 5
  let height = 20

  if (window.matchMedia("(max-width: 600px)").matches) {
    numberPoints = 4
    height = 15
  } else if (window.matchMedia("(max-width: 1024px)").matches) {
      numberPoints=4
      height=20
  } else {
      numberPoints=5
  }

  useEffect(()=>{
    console.log(authorized);
    

    if(authorized){
      setLoadPicture(true)
      setLoadPicture2(true)
      axios.get(process.env.REACT_APP_TEMP_ASSISTANTS+"?vertical="+user.verticals[0].vertical_id+"&english_level="+user.english_level, {
        headers:{
          "X-API-KEY": process.env.REACT_APP_X_APIKEY
        }
      })
      .then((res)=>{
        setAssistant(res.data[0])
        setLoadPicture(false)
        setLoadPicture2(false)
      })
    }
  }, [authorized])
    
  return (
    <div className="min-h-[90vh] h-full flex flex-col justify-center md:justify-end items-center  w-full bg-black">
      {isLoading || loadPicture ?
        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 loading loading-ring loading-lg text-white"></span>
      :isAuthenticated
      ? <><div className="relative md:absolute w-[200px]  h-[200px] rounded-full md:w-full md:h-[89vh]  md:rounded-t-lg">
      <video
        src={assistant?.idle_video}
        autoPlay={true}
        loop={true}
        playsInline
        muted
        className={`absolute w-full h-full object-contain rounded-full md:rounded-t-lg  ${
          state === "disconnected" ||
          state === "connecting" ||
          state === "listening" ||
          state === "thinking" ||
          state === "initializing"
            ? "z-20"
            : state === "speaking" && "-z-10"
        }`}
      />
      <video
        src={assistant?.speaking_video}
        autoPlay={true}
        loop={true}
        playsInline
        muted
        className=" w-full h-full object-contain rounded-full md:rounded-t-lg z-10"
      />
    </div>
    <div className="h-[150px] z-[50] w-full bg-gradient-to-t from-black to-transparent">
    <BarVisualizer
      state={state}
      barCount={numberPoints}
      trackRef={audioTrack}
      className="agent-visualizer !bg-gradient-to-t !from-black !to-transparent"
      options={{ minHeight: height }}
    />
  </div>
  </>
    :<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2   flex flex-col justify-center items-center gap-5">
      <h1 className=" text-lg font-semibold text-white">Log in before you start </h1>
      <button
    onClick={() => {
      loginWithRedirect({
        redirectUri: window.location.origin+"/live"
      })
    }}
    className="custom-transition bg-bunker-600 rounded-full text-white hover:text-black hover:bg-[#e4e4e4] px-4 text-sm font-normal py-2"
  >
    {t("sign-in")}
  </button>
      </div>}
      
    </div>
  );
}

function ControlBar({ onConnectButtonClicked, agentState, showTimer, setShowTimer, loadPicture, setIsSideOpen, isSideOpen, showSide, setShowSide }) {

  const handleShowSide = () => {
      setIsSideOpen(true);
      setShowSide("show");
  };

  const hideSide = () => {
    setIsSideOpen(false);
    setShowSide("hide");
  };

  const krisp = useKrispNoiseFilter();
  useEffect(() => {
    krisp.setNoiseFilterEnabled(true);
  }, []);

  return (
    <div className="relative h-[10vh] w-full !bg-pr-600 flex justify-between items-center z-[52] md:z-auto">
      <div className="w-auto h-full flex justify-center items-center pl-2 md:pl-5">
        {showTimer && <Timer/>}
      </div>
      <div className="absolute h-full left-1/2 -translate-x-1/2 flex justify-center items-center">
      <AnimatePresence>
        {agentState === "disconnected" && (
          <motion.button
            initial={{ opacity: 0, top: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, top: "-10px" }}
            transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
            className="uppercase btn btn-xs md:btn-sm !text-xs md:!text-md !bg-main2 hover:bg-bunker-900  !border-solid !border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 font-semibold rounded-xl custom-transition"
            onClick={() => onConnectButtonClicked()}
            disabled={loadPicture ? true : false}
          >
            Start videocall
          </motion.button>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {agentState !== "disconnected" && agentState !== "connecting" && (
          <motion.div
            initial={{ opacity: 0, top: "10px" }}
            animate={{ opacity: 1, top: 0 }}
            exit={{ opacity: 0, top: "-10px" }}
            transition={{ duration: 0.4, ease: [0.09, 1.04, 0.245, 1.055] }}
            className="flex h-full items-center  justify-center gap-2 md:gap-4"
          >
            <VoiceAssistantControlBar controls={{ leave: false }}/>
        <button className="flex justify-center items-center">
          <div
            onClick={()=>{
              setIsSideOpen(!isSideOpen)
              if(showSide === "show"){
                setShowSide("hide")
              }else if(showSide === "hide"){
                setShowSide("show")
              }
            }}
            className="group p-2 rounded-full hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9]  custom-transition flex justify-center items-center cursor-pointer "
          >
            <CgTranscript  className="text-bunker-800 dark:text-[#7d7d7d] dark:group-hover:text-[#94949D] text-base md:text-xl" />
          </div>
        </button>
            <DisconnectButton onClick={() => {
              setShowTimer(false)
              hideSide()
            }}>
              <CloseIcon />
            </DisconnectButton>
          </motion.div>
        )}
      </AnimatePresence>
      </div>
      <div className="w-auto h-full ">
    
      </div>
    </div>
  );
}

function onDeviceFailure(error) {
  console.error(error);
  alert(
    "Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab"
  );
}
