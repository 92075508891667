import React, { useEffect, useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import SkillsAssistant from "./SkillsAssistant";
import TopicsAssistant from "./TopicsAssistant";
import SkillsAssistantSkeleton from "../UI/SkillsAssistantSkeleton";


const SideBarRight = (props) => {
  const sidebarRef = useRef();
  const [see, setSee] = useState()

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      props.setIsSideOpen2(false);
      props.sideStateRight("hide");
    }
  };

  const hideSide = () => {
    props.setIsSideOpen2(false);
    props.sideStateRight("hide");
  };

  const seeMore = () =>{
    const description = document.querySelector(".desc")
    description.classList.add("line-clamp-none")
    description.classList.remove("line-clamp-4")
    setSee(true)
  }

  const seeLess = () =>{
    const description = document.querySelector(".desc")
    description.classList.remove("line-clamp-none")
    description.classList.add("line-clamp-4")
    setSee(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div
      ref={sidebarRef}
      className={`sideR h-[100dvh] overflow-hidden overflow-y-scroll w-full ${
      props.isSideOpen2 ? 'translate-x-0 max-w-96' : 'translate-x-full max-w-0'
    }  absolute right-0 top-0 bottom-0 border-l border-[#26272B] dark:border-[#f9f9f9] bg-pr-800 dark:bg-white custom-transition p-4 md:p-6 pr-0 z-10 "
    `}>
      {props.isSideOpen2 &&
      <IoIosClose
      onClick={hideSide}
      className="absolute top-2 left-2 text-3xl text-[#94949D] dark:text-[#7D7D7D] cursor-pointer"
    />
      }
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-2 justify-start items-center">
          {!props.loading ? (
            <img
              src={props.dataAssistant?.image}
              alt="logo"
              className="w-[200px] h-[200px] object-cover rounded-full dark:shadow-sm dark:shadow-[#eeeeee]"
            />
          ) : (
            <div className="skeleton bg-[#26272B] w-[200px] h-[200px] rounded-full"></div>
          )}
          <span className="shrink-0 bg-[#26272B] dark:bg-[#f9f9f9] h-[1px] w-full my-4"></span>

          <div className="flex flex-col justify-start items-start gap-2 w-full">
            {!props.loading ? (
              <>
                <div className="flex flex-col">
                  <h2 className="font-semi-bold line-clamp-1 text-ellipsis text-start overflow-hidden whitespace-normal text-bunker-50 dark:text-[#7d7d7d] text-lg font-bold">
                    {props.dataAssistant?.name}
                  </h2>
                  <span className="text-[#f9f9f9] text-xs">
                    {props.dataAssistant?.role}
                  </span>
                </div>
                <p className="desc text-muted-foreground font-normal text-sm  text-[#9999A3] line-clamp-4">
                  {props.dataAssistant?.description} 
                </p>
                {see ? <span onClick={seeLess} className="text-xs font-semibold cursor-pointer">See less</span> : <span onClick={seeMore} className="text-xs font-semibold cursor-pointer">See more</span>}
              </>
            ) : (
              <>
                <div className="flex flex-col gap-2">
                  <div className="skeleton bg-[#26272B] w-1/2 h-3"></div>
                  <div className="skeleton bg-[#26272B] w-32 h-3"></div>
                </div>
                <div className="skeleton bg-[#26272B] w-full h-5"></div>
                <div className="skeleton bg-[#26272B] w-full h-5"></div>

                <div className="skeleton bg-[#26272B] w-full h-5"></div>

                <div className="skeleton bg-[#26272B] w-full h-5"></div>
              </>
            )}
          </div>
          <span className="shrink-0 bg-[#26272B] dark:bg-[#f9f9f9] h-[1px] w-full my-4"></span>

          {!props.loading
          ? <SkillsAssistant data={props.dataAssistant}/>
          : <SkillsAssistantSkeleton/>
          }

          <span className="shrink-0 bg-[#26272B] dark:bg-[#f9f9f9] h-[1px] w-full my-4"></span>

          {!props.loading
          ? <TopicsAssistant data={props.dataAssistant}/>
          : <SkillsAssistantSkeleton/>
          }
          
        </div>
      </div>
    </div>
  );
};

export default SideBarRight;
