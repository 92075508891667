import React from 'react'
import NotFound from '../pages/NotFound'
import {Route, Routes } from 'react-router-dom'
import Chat from '../pages/Chat'
import Home from '../pages/Home'
import CallLiveKit from '../pages/CallLiveKit'

const AppRouter = () => {
  return (
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/chat" element={<Chat/>} />
        <Route path="/chat/assistant/:assistantId" element={<Chat/>} />
        <Route path="*" element={<NotFound />} />
        <Route path="/live" element={<CallLiveKit />} />
      </Routes>
  )
}

export default AppRouter
