import React, { useContext, useEffect, useRef, useState } from "react";
import SideBar from "../layouts/SideBar";
import Header from "../layouts/Header";
import InputChat from "../UI/InputChat";
import SideBarRight from "../layouts/SideBarRight";
import "../../assets/css/scrollBar.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../utils/userContext";
import Mic from "../UI/Mic";
import Call from "../UI/Call";
import VideoCall from "../UI/VideoCall";
import OverlaySettings from "../layouts/OverlaySettings";
import { MdOutlineAutoAwesome } from "react-icons/md";
import ModalInactive from "../layouts/ModalInactive";
import { t } from "i18next";
import OverlayWord from "../layouts/OverlayWord";
import CallPage from "./CallPage";
import VideoCallPage from "./VideoCallPage";
import * as amplitude from "@amplitude/analytics-browser";
import OverlayQuestions from "../layouts/OverlayQuestions";

amplitude.init("f8358a3b2025d29fe2822013871b15f1");

const Chat = () => {
  const token = window.localStorage.getItem("token_user");
  const [sideState, setSideState] = useState("show");
  const [isSideOpen, setIsSideOpen] = useState(true); 
  const [isSideOpen2, setIsSideOpen2] = useState(true); 
  const [sideStateRight, setSideStateRight] = useState("show");
  const [ws, setWs] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [currentAssistantMessage, setCurrentAssistantMessage] = useState("");
  let { assistantId } = useParams();
  const [messageQueue, setMessageQueue] = useState([]);
  const [typingMessage, setTypingMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [dataAssistant, setDataAssistant] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [starterMessages, setStarterMessages] = useState([]);
  const [transcription, setTranscription] = useState("");
  const [recording, setRecording] = useState(false);
  const [tempAudioUrl, setTempAudioUrl] = useState();
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [callActive, setCallActive] = useState(false);
  const [videoCallActive, setVideoCallActive] = useState(false);
  const [status, setStatus] = useState();
  const [fullMessage, setFullMessage] = useState(
    "Hello, how can I assist you today?"
  );
  const [dataSpeach, setDataSpeach] = useState();
  const [selectedWordInfo, setSelectedWordInfo] = useState(null);

  const audioElementRef = useRef(null);
  const [mediaSource] = useState(new MediaSource());
  const [sourceBuffer, setSourceBuffer] = useState(null);
  const queue = useRef([]);
  const isAppending = useRef(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [mode, setMode] = useState("text");
  const [session, setSession] = useState();
  const [audioVideoCall, setAudioVideoCall] = useState();
  const [audioVideoCallTemp, setAudioVideoCallTemp] = useState(false);
  const [loadingCall, setLoadingCall] = useState(false);
  const [audioPredeterminado, setAudioPredeterminado] = useState(undefined);
  const [historial, setHistorial] = useState(null)
  const [noAggended, setNoAggended] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [questions, setQuestions] = useState(null)
  const [suggestions, setSuggestions] = useState(null)
  const [type, setType] = useState(null)
  const [aggendedSoldOut, setAggendedSoldOut] = useState({
    status: false,
    message: ""
  })

  const { user, setUser, authorized, setAuthorized } = useContext(UserContext);

  const {
    user: userBack,
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
  } = useAuth0();

  const sendMessage = (param = null, feed = null) => {
    if (param) {
      if (ws) {
        if (dataAssistant.speechace_enabled && feed) {
          ws.send(
            JSON.stringify({
              user: "user",
              message: param,
              speechace_feedback: feed,
            })
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { user: "user", message: param },
          ]);
          setInput("");

          const textArea = document.getElementById("input");
          textArea.value = "";
          textArea.disabled = setLoadingChat(true);
        } else {
          ws.send(JSON.stringify({ user: "user", message: param }));
          setMessages((prevMessages) => [
            ...prevMessages,
            { user: "user", message: param },
          ]);
          setInput("");
        }
      }
    } else {
      if (ws && input.trim()) {
        // Verifica que el input no esté vacío
        ws.send(JSON.stringify({ user: "user", message: input }));
        setMessages((prevMessages) => [
          ...prevMessages,
          { user: "user", message: input },
        ]);
        setInput("");

        const textArea = document.getElementById("input");
        textArea.value = "";
        textArea.disabled = setLoadingChat(true);
      }
    }
  };

  const sendStarterMessage = (text) => {
    if (ws && text.trim()) {
      // Verifica que el input no esté vacío
      ws.send(JSON.stringify({ user: "user", message: text }));
      setMessages((prevMessages) => [
        ...prevMessages,
        { user: "user", message: text },
      ]);
      setInput("");

      const textArea = document.getElementById("input");
      textArea.value = "";
      textArea.disabled = setLoadingChat(true);
    }
  };

  const formatMessage = (message) => {
    return message
      .replace(
        /### (.*?)\n/g,
        '<h2 class="font-bold text-base md:text-lg">$1</h2>'
      ) // Manejar títulos
      .replace(
        /# ### (.*?)\n/g,
        '<h3 class="font-bold text-base md:text-md">$1</h3>'
      ) // Manejar subtítulos
      .replace(/ \n\n/g, "<br><br><br>") // Manejar espacio antes de \n\n
      .replace(/\n\n/g, "<br><br><br>") // Manejar \n\n
      .replace(/ \n/g, "<br><br>") // Manejar espacio antes de \n
      .replace(/\n/g, "<br><br>") // Manejar \n
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(
        /\{\{\s?(.*?)\s?\}\}/g,
        '<span className="italic font-semibold md:font-bold text-[#4D8CF4] p-2 rounded-md ">$1</span>'
      );
  };

  const showModalInactive = () => {
    const modalI = document.querySelector(".modalI");

    modalI.classList.add("flex");
    modalI.classList.remove("hidden");
  };

  function hexStringToByteArray(hexString) {
    const result = [];
    for (let i = 0; i < hexString.length; i += 2) {
      result.push(parseInt(hexString.substr(i, 2), 16));
    }
    return new Uint8Array(result);
  }

  const clickWord = (word) => {
    const cleanedWord = word.replace(/[.,/#!$%^&*;:{}=\-_`~()?¿]/g, "");

    const wordInfo = dataSpeach.word_score_list.find(
      (item) =>
        item.word.replace(/[.,/#!$%^&*;:{}=\-_`~()?¿]/g, "") === cleanedWord
    );
    if (wordInfo) {
      setSelectedWordInfo(wordInfo);
    }
  };

  const endCall = () => {
    const schema = {
      session_id: session,
    };
    axios
      .post(process.env.REACT_APP_URL_END_VIDEOCALL, schema, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const audioElement = audioElementRef.current;

    const handleSourceOpen = () => {
      const sb = mediaSource.addSourceBuffer("audio/mpeg");
      sb.mode = "sequence";

      sb.addEventListener("updateend", () => {
        if (queue.current.length > 0) {
          sb.appendBuffer(queue.current.shift());
        } else {
          isAppending.current = false;
        }
      });

      setSourceBuffer(sb);
    };

    mediaSource.addEventListener("sourceopen", handleSourceOpen);

    if (audioElement) {
      // Asignamos el src en este punto, justo después de configurar el event listener
      audioElement.src = URL.createObjectURL(mediaSource);
      // Espera un breve momento para asegurar que el audio se cargue y luego lo reproduce
      audioElement.addEventListener("playing", () => {
        setIsAudioPlaying(true);
        setLoadingCall(false);
      });
      setTimeout(() => {
        audioElement.play().catch((err) => {});
      }, 10); // Ajusta el tiempo de espera si es necesario
    }
    return () => {
      mediaSource.removeEventListener("sourceopen", handleSourceOpen);
    };
  }, [mediaSource, callActive]);

  useEffect(() => {
    const obtenerToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getIdTokenClaims();
          let data = {
            auth_token: token.__raw,
          };
          axios
            .post(process.env.REACT_APP_AUTH0, data, {
              headers: {
                "X-API-KEY": process.env.REACT_APP_X_APIKEY,
              },
            })
            .then((res) => {
              // window.localStorage.setItem("token_user", res.data.tokens.access)
              setAuthorized(true);
              setUser(res.data.user);
            })
            .catch((err) => {
              if (err.response.data.detail === "User account is inactive.") {
                showModalInactive();
              }
            });
        } catch (error) {
          console.error("Error al obtener el token:", error);
        }
      }
    };

    obtenerToken();
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    if (dataAssistant != null && user != null) {
      let urlWs;

      if (mode === "call" || mode === "text") {
        urlWs = `${process.env.REACT_APP_URL_WS_CHAT}?assistant_id=${dataAssistant?.assistant_id}&user_id=${user.id}&mode=${mode}`;
      } else if (mode === "video_call") {
        urlWs = `${process.env.REACT_APP_URL_WS_VIDEO}?assistant_id=${dataAssistant?.assistant_id}&user_id=${user.id}`;
      }
      
      const websocket = new WebSocket(urlWs);
      setWs(websocket);

      // Escuchar cuando el WebSocket se conecta
      websocket.onopen = () => {};

      websocket.onmessage = (event) => {
        
        const dataParse = JSON.parse(event.data);
        if (dataParse.new_thread_id == null) {
          if (dataParse.audio_chunk != null) {
            const audioChunk = hexStringToByteArray(dataParse.audio_chunk);
            if (
              sourceBuffer &&
              !sourceBuffer.updating &&
              !isAppending.current
            ) {
              sourceBuffer.appendBuffer(audioChunk);
              isAppending.current = true;
            } else {
              queue.current.push(audioChunk);
            }
          }
          if (dataParse.starter_messages != null) {
            setStarterMessages(dataParse.starter_messages);
          } else {
            setStarterMessages([]);
          }
          if (dataParse.audio_url_D_ID != null) {
            setAudioVideoCallTemp(false);
            setAudioVideoCall(dataParse.audio_url_D_ID);
          }
          // if (dataParse.type === "init_audio") {
          //   setTimeout(() => {
          //     setAudioVideoCallTemp(false);
          //     setAudioVideoCall(dataParse.audio_url_D_ID);
          //   }, 1000);
          // }
          if (dataParse.wait_audio_url != null) {
            setAudioVideoCallTemp(true);
            setAudioVideoCall(dataParse.wait_audio_url);
          }
          if (dataParse.status === "session_ended") {
            setVideoCallActive(false);
            endCall();
          }
          if(dataParse.message === "No valid session found for the video call."){
            setNoAggended(true)
          }
          if(dataParse.message === "You have reached the daily session limit." || dataParse.message === "You have reached the daily session limit."){
            setAggendedSoldOut({
              status: true,
              message: dataParse.message
            })
          }
          if (dataParse.video_call_session_log != null) {
            if (dataParse.video_call_session_log.end_time == null) {
              amplitude.track("Inicia una llamada", {
                userId: user.id,
                userEmail: user.email,
                userName: user.full_name,
                buttonName: "VideoCall",
                page: "Chat",
                assistant: dataAssistant.name,
                assistantId: dataAssistant.assistant_id,
                assistantRole: dataAssistant.role,
                time_start: dataParse.video_call_session_log.start_time,
              });
              setSession(
                dataParse.video_call_session_log.video_call_session_log_id
              );
            } else {
              amplitude.track("Termina la llamada", {
                userId: user.id,
                userEmail: user.email,
                userName: user.full_name,
                buttonName: "Call out",
                page: "VideoCall",
                time_end: dataParse.video_call_session_log.end_time,
                time_start: dataParse.video_call_session_log.start_time,
                assistant: dataAssistant.name,
                assistantId: dataAssistant.assistant_id,
                assistantRole: dataAssistant.role,
              });
            }
          }
          if (dataParse.audio_url != null) {
            // setTempAudioUrl(dataParse.audio_url); // Guardar temporalmente el audio_url
            setAudioVideoCall(dataParse.audio_url);
            setLoadingAudio(false);
          } else {
            setTempAudioUrl(null); // Guardar temporalmente el audio_url
            setLoadingAudio(true);
          }
          if (dataParse.status != null) {
            setStatus(dataParse.status);
          }
          if(dataParse.type != null){
            setType(dataParse.type)
          }
          if(dataParse.suggestions != null){
            setSuggestions(dataParse.suggestions)
          }
          if (dataParse.full_message != null) {
            setFullMessage(dataParse.full_message);
          }
          if (dataParse.history_message != null) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                user: dataParse.role,
                message: dataParse.history_message
                  ?.replace(
                    /### (.*?)\n/g,
                    '<h2 class="font-bold text-base md:text-lg">$1</h2>'
                  ) // Manejar títulos
                  .replace(
                    /# ### (.*?)\n/g,
                    '<h3 class="font-bold text-base md:text-md">$1</h3>'
                  ) // Manejar subtítulos.replace(/ \n\n/g, "<br><br><br>") // Manejar espacio antes de \n\n
                  .replace(/\n\n/g, "<br><br><br>") // Manejar \n\n
                  .replace(/ \n/g, "<br><br>") // Manejar espacio antes de \n
                  .replace(/\n/g, "<br><br>") // Manejar \n
                  .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
                  .replace(
                    /\{\{\s?(.*?)\s?\}\}/g,
                    '<span className="italic font-semibold md:font-bold text-[#4D8CF4] p-2 rounded-md ">$1</span>'
                  ),
                url_audio: dataParse.audio_url != null && dataParse.audio_url,
              },
            ]);
          }
          if (dataParse.speechace_feedback != null) {
            setDataSpeach(dataParse.speechace_feedback.text_score);
          } else {
            if (dataParse.user === "assistant") {
              if (dataParse.message != null) {
                setMessageQueue((prevQueue) => [
                  ...prevQueue,
                  dataParse.message,
                ]);
              }
            } else if (dataSpeach != null) {
              setMessages((prevMessages) => {
                if (
                  prevMessages.length === 0 ||
                  prevMessages[prevMessages.length - 1].message !==
                    dataParse.message
                ) {
                  return [
                    ...prevMessages,

                    {
                      user: dataParse.user,
                      message: dataSpeach.word_score_list.map(
                        (word, index) => word.word
                      ),
                    },
                  ];
                } else {
                  return prevMessages;
                }
              });
            } else {
              if (dataParse.user === "user") {
                setMessages((prevMessages) => {
                  if (
                    prevMessages.length === 0 ||
                    prevMessages[prevMessages.length - 1].message !==
                      dataParse.message
                  ) {
                    return [
                      ...prevMessages,

                      { user: dataParse.user, message: dataParse.message },
                    ];
                  } else {
                    return prevMessages;
                  }
                });
              }
            }
          }
        } else {
          window.localStorage.setItem("thread_id", dataParse.new_thread_id);
          setHistorial(true)
        }
      };

      websocket.onclose = () => {};

      // Limpiar la conexión WebSocket al desmontar el componente
      return () => {
        websocket.close();
      };
    }
  }, [dataAssistant, user, sourceBuffer, mode]);

  useEffect(() => {
    const input = document.getElementById("input");
    const mic = document.getElementById("mic");
    const call = document.getElementById("call");

    if (messageQueue.length > 0) {
      const intervalId = setInterval(() => {
        setTypingMessage((prev) => {
          const nextWord = messageQueue[0].split(" ")[0];
          setMessageQueue((prevQueue) => {
            const newQueue = [...prevQueue];
            newQueue[0] = newQueue[0].substring(nextWord.length).trim();
            if (newQueue[0] === "") {
              newQueue.shift();
            }
            return newQueue;
          });
          return (
            prev +
            " " +
            nextWord
              ?.replace(
                /### (.*?)\n/g,
                '<h2 class="font-bold text-base md:text-lg">$1</h2>'
              ) // Manejar títulos
              .replace(
                /# ### (.*?)\n/g,
                '<h3 class="font-bold text-base md:text-md">$1</h3>'
              ) // Manejar subtítulos
              .replace(/ \n\n/g, "<br><br>") // Manejar espacio antes de \n\n
              .replace(/\n\n/g, "<br><br>") // Manejar \n\n
              .replace(/ \n/g, "<br>") // Manejar espacio antes de \n
              .replace(/\n/g, "<br>") // Manejar \n
              .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
              .replace(
                /\{\{\s?(.*?)\s?\}\}/g,
                '<span className="italic font-semibold md:font-bold text-[#4D8CF4] p-2 rounded-md ">$1</span>'
              )
          );
        });

        if (messageQueue.length === 0) {
          clearInterval(intervalId);
        }
        input.disabled = true;
        mic.disabled = true;
        if (call != null) {
          call.disabled = true;
        }
        setLoadingChat(false);
      }, 80); // Ajusta el tiempo de espera para el efecto de "máquina de escribir"

      return () => clearInterval(intervalId);
    } else if (typingMessage) {
      const formattedMessage = formatMessage(typingMessage.trim());
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          user: "assistant",
          message: formattedMessage,
          url_audio: tempAudioUrl,
        },
      ]);
      setTypingMessage(""); // Reset typing message for the next message
      setTempAudioUrl(null);
      input.disabled = false;
      mic.disabled = false;
      if (call != null) {
        call.disabled = false;
      }
    }
  }, [messageQueue, typingMessage, tempAudioUrl]);

  useEffect(() => {
    if (currentAssistantMessage) {
      const timeoutId = setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { user: "assistant", message: currentAssistantMessage.trim() },
        ]);
        setCurrentAssistantMessage("");
      }, 500); // Ajusta el tiempo según sea necesario

      return () => clearTimeout(timeoutId);
    }
  }, [currentAssistantMessage]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, typingMessage]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_URL_ASSISTANTS}${assistantId}/`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          },
        }
      )
      .then((res) => {
        setDataAssistant(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const textArea = document.getElementById("input");

    if (status === "connected") {
      if (recording) {
        textArea.disabled = true;
      } else {
        textArea.disabled = false;
      }
    }
  }, [recording, status]);

  useEffect(() => {
    if(questionModal){
      axios.get(process.env.REACT_APP_GET_QUESTIONS,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            "Authorization": "Bearer " + token,
          },
        })
        .then((res)=>{
          setQuestions(res.data)
          setQuestionModal(true)
        })
        .catch((err)=>{
          if(err.status === 404 || err.response.data.message === "No feedback questions available at this time."){
            setQuestions(null)
            setQuestionModal(false)
          }
        })
    }
  }, [questionModal])
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isDesktop = window.innerWidth >= 768;
      const initialState = isDesktop && authorized;
      setIsSideOpen(initialState);
      setIsSideOpen2(initialState);
    }
  }, [authorized]);

  

  return (
    <div className="flex h-screen overflow-hidden bg-main2 dark:bg-white relative">
      <audio ref={audioElementRef} onWaiting={() => setIsAudioPlaying(false)} />
      <SideBar sideState={setSideState} historial={historial} setIsSideOpen={setIsSideOpen} isSideOpen={isSideOpen}/>
      <div className="flex flex-col justify-between items-center w-full">
        <Header
          sideState={sideState}
          showSide={setSideState}
          setIsSideOpen={setIsSideOpen}
          isSideOpen={isSideOpen}
          setIsSideOpen2={setIsSideOpen2}
          isSideOpen2={isSideOpen2}
          showSideRight={setSideStateRight}
          sideStateRight={sideStateRight}
          dataAssistant={dataAssistant}
          loading={loading}
        />
        <main className="h-[90vh] w-full md:w-[60%] flex flex-col justify-between items-center">
          <div className=" container flex w-full flex-col justify-star items-center 2xl:max-h-[800px] h-[80%] md:h-full overflow-y-scroll overflow-x-hidden align-middle gap-5">
            <div className="max-w-3xl min-max-w-3xl  w-full flex flex-col items-center justify-center text-center gap-1 pt-12 lg:pt-32 ">
              {!loading ? (
                <>
                  <img
                    className="w-[64px] h-[64px] object-cover rounded-full dark:shadow-md dark:shadow-[#eeeeee]"
                    src={dataAssistant?.image}
                    alt="Logo"
                  />
                  <h2 className="font-semi-bold line-clamp-1 text-ellipsis  overflow-hidden whitespace-normal text-bunker-50 dark:text-[#7d7d7d] text-sm">
                    {dataAssistant?.role}
                  </h2>
                  <p className="text-muted-foreground font-normal text-xs truncate text-[#9999A3]">
                    Por @EnglishCode
                  </p>
                </>
              ) : (
                <>
                  <div className="skeleton bg-[#26272B] w-[64px] h-[64px] rounded-full"></div>
                  <div className="skeleton bg-[#26272B] w-20 h-3"></div>

                  <div className="skeleton bg-[#26272B] w-10 h-3"></div>
                </>
              )}
            </div>

            <div className="group relative max-w-3xl min-max-w-3xl w-full p-4 md:p-2">
              {!authorized && (
                <div className="w-full  flex flex-col justify-center items-center gap-2 rounded-lg">
                  <h2 className="text-sm text-white font-bold">
                    Join English<span className="text-bunker-500">Code</span> to
                    access to assistants
                  </h2>
                  <button
                    onClick={() => loginWithRedirect()}
                    className="custom-transition bg-bunker-600 rounded-full text-white hover:text-black hover:bg-[#e4e4e4] px-4 text-sm font-normal py-2"
                  >
                    {t("sign-in")}
                  </button>
                </div>
              )}
              <div className="w-full p-2 flex flex-col gap-2 md:gap-0 md:flex-row justify-between items-start">
                {starterMessages.length > 0 &&
                  starterMessages.map((starter, index) => (
                    <div
                      onClick={() => {
                        sendStarterMessage(starter.message);
                      }}
                      key={index}
                      className="w-full md:w-[30%] h-auto md:min-h-[100px] flex flex-col gap-2 justify-start items-center rounded-xl bg-pr-800 border border-bunker-800 dark:bg-[#f9f9f9] p-3 cursor-pointer hover:bg-black custom-transition hover:-translate-y-2 text-center"
                    >
                      <MdOutlineAutoAwesome className="text-sm text-bunker-600" />
                      <p className="text-bunker-50 dark:text-[#7d7d7d] text-sm">
                        {starter.display}
                      </p>
                    </div>
                  ))}
              </div>
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`m-0 p-[8px] flex flex-row items-start gap-2 ${
                    msg.user === "assistant" ? "justify-start" : "justify-end"
                  }`}
                >
                  <div
                    className={`flex ${
                      msg.user === "assistant" ? "flex-row" : "flex-row-reverse"
                    } items-start gap-2`}
                  >
                    <div className="mt-0 flex flex-col gap-3 items-center">
                      <img
                        className="min-w-[24px] w-[24px] h-[24px] object-cover rounded-full "
                        src={
                          msg.user === "assistant"
                            ? dataAssistant?.image
                            : userBack?.picture
                        }
                        alt="Logo"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div
                        className={`flex  ${
                          msg.user === "assistant"
                            ? "justify-start"
                            : "justify-end"
                        } items-end justify-start gap-2`}
                      >
                        <h2 className="md:w-auto font-semi-bold line-clamp-1 text-ellipsis overflow-hidden whitespace-normal text-bunker-50 dark:text-[#7d7d7d] text-sm">
                          {msg.user === "assistant"
                            ? dataAssistant?.name
                            : user?.full_name}
                        </h2>
                        {/* {msg.user === "assistant" && (
                          <AudioPlayer
                            audio={msg.url_audio != null && msg.url_audio}
                          />
                        )} */}
                      </div>
                      <div className="w-auto mt-1 max-w-2xl rounded-2xl px-3 min-h-12 flex justify-center py-3 bg-pr-200 dark:bg-white gap-1">
                        {dataSpeach ? (
                          msg.user === "assistant" ? (
                            <p
                              className="text-sm md:text-base text-bunker-50 dark:text-black"
                              dangerouslySetInnerHTML={{ __html: msg.message }}
                            />
                          ) : (
                            msg.message?.split(" ").map((word, index) => {
                              const cleanedWord = word.replace(
                                /[.,/#!$%^&*;:{}=\-_`~()?¿]/g,
                                ""
                              );
                              const wordInfo = dataSpeach.word_score_list.find(
                                (item) =>
                                  item.word.replace(
                                    /[.,/#!$%^&*;:{}=\-_`~()?¿]/g,
                                    ""
                                  ) === cleanedWord
                              );

                              // Determinar el color basado en la puntuación
                              const wordColor = wordInfo
                                ? wordInfo.quality_score <= 50
                                  ? "rgb(248 113 113)"
                                  : "rgb(74 222 128)"
                                : "inherit";

                              return (
                                <span
                                  key={index}
                                  className="text-sm md:text-base cursor-pointer hover:underline custom-transition"
                                  style={{ color: wordColor }}
                                  onClick={() => clickWord(word)}
                                  dangerouslySetInnerHTML={{
                                    __html: word + " ",
                                  }}
                                />
                              );
                            })
                          )
                        ) : (
                          <p
                            className="text-sm md:text-base text-bunker-50 dark:text-black"
                            dangerouslySetInnerHTML={{ __html: msg.message }}
                          />
                        )}

                        {/* <ChatMessage message={msg.message} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {typingMessage && (
                <div className="m-0 flex flex-row items-start gap-2 justify-start">
                  <div className="flex flex-row items-start gap-2">
                    <div className="mt-0 flex flex-col gap-3 items-center">
                      <img
                        className="min-w-[24px] w-[24px] h-[24px] object-cover rounded-full dark:shadow-sm dark:shadow-[#eeeeee]"
                        src={dataAssistant?.image}
                        alt="Logo"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex justify-start items-center gap-2">
                        <h2 className="font-semi-bold line-clamp-1 text-ellipsis overflow-hidden whitespace-normal text-bunker-50 dark:text-[#7d7d7d] text-sm">
                          {dataAssistant?.name}
                        </h2>
                        <span className="loading loading-spinner loading-xs"></span>
                      </div>
                      <div className="w-auto mt-1 max-w-2xl rounded-2xl px-3 min-h-12 flex justify-center py-3 bg-pr-200 dark:bg-white">
                        <p
                          className="text-xs md:text-base text-bunker-50 dark:text-black"
                          dangerouslySetInnerHTML={{
                            __html: formatMessage(typingMessage),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div dangerouslySetInnerHTML={{ __html: completeMessage }} />
      {inCodeBlock && (
        <SyntaxHighlighter language="javascript" style={dracula}>
          {tempCode.trim()}
        </SyntaxHighlighter>
      )} */}
              {loadingChat && (
                <div className="m-0 p-[8px] flex flex-row items-start gap-2 justify-start">
                  <div className="flex flex-row gap-2">
                    <div className="mt-0 hidden md:flex flex-col gap-3 items-center">
                      <div className="w-[24px] h-[24px] skeleton bg-[#26272B]  rounded-full"></div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-2">
                        <div className="skeleton  bg-[#26272B]  w-20 h-3"></div>
                        <div className="skeleton  bg-[#26272B] w-2xl h-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>
          {status === "connected" ? (
            <div className="flex w-full  max-w-3xl items-center mb-6 px-2">
              <InputChat
                setInput={setInput}
                input={input}
                send={sendMessage}
                transcription={transcription}
              />
              {/* <Call
                callActive={callActive}
                setCallActive={setCallActive}
                setMode={setMode}
                amplitude={amplitude}
                assistant={dataAssistant}
                user={user}
              />
              <VideoCall
                videoCallActive={videoCallActive}
                setVideoCallActive={setVideoCallActive}
                assistant={dataAssistant}
                session={setSession}
                mode={setMode}
                setVideo={setAudioVideoCall}
              /> */}
              <Mic
                transcription={setTranscription}
                recording={setRecording}
                dataAssistant={dataAssistant}
                sendMessage={sendMessage}
                dataS={setDataSpeach}
              />
            </div>
          ) : status === "connecting" ? (
            <div className="flex w-full  max-w-3xl items-center mb-6 px-2 gap-2">
              <div className="skeleton bg-[#26272B] w-full h-10" />
              <div className="skeleton bg-[#26272B] w-10 h-10 rounded-full" />
            </div>
          ) : null}
        </main>
      </div>
      <SideBarRight
        sideStateRight={setSideStateRight}
        setIsSideOpen2={setIsSideOpen2}
        isSideOpen2={isSideOpen2}
        dataAssistant={dataAssistant}
        loading={loading}
      />
      <OverlaySettings />
      {selectedWordInfo && (
        <OverlayWord data={selectedWordInfo} dataSet={setSelectedWordInfo} />
      )}
      <ModalInactive />
      {callActive && (
        <CallPage
          sendMessage={sendMessage}
          setCallActive={setCallActive}
          assistant={dataAssistant}
          fullMessage={fullMessage}
          setMode={setMode}
          isPlaying={isAudioPlaying}
          loading={loadingCall}
          setLoading={setLoadingCall}
          status={status}
          amplitude={amplitude}
          user={user}
        />
      )}
      {videoCallActive && (
        <VideoCallPage
          sendMessage={sendMessage}
          setVideoCallActive={setVideoCallActive}
          assistant={dataAssistant}
          fullMessage={fullMessage}
          audio={audioVideoCall}
          session_id={session}
          mode={setMode}
          temp={audioVideoCallTemp}
          agended={noAggended}
          setAgended={setNoAggended}
          aggendedSO={aggendedSoldOut}
          setAggendedSO={setAggendedSoldOut}
          setQuestions={setQuestionModal}
          status={status}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          videoCallActive={videoCallActive}
          type={type}
        />
      )}
      {questions ? questionModal && <OverlayQuestions data={questions} close={setQuestionModal} session_id={session}/> : null}
    </div>
  );
};

export default Chat;
