import React, { useContext, useEffect, useState } from "react";
import QA2 from "../../assets/img/qa2.png";
import CardSection1HomeActivities from "../UI/CardSection1HomeActivities";
import CardSection1HomeAssistant from "../UI/CardSection1HomeAssistant";
import CardSection1ActivityHomeSkeleton from "../UI/CardSection1ActivityHomeSkeleton";
import CardSection1AssistantHomeSkeleton from "../UI/CardSection1AssistantHomeSkeleton";
import { UserContext } from "../utils/userContext";
import { t } from "i18next";



const Section1Home = (props) => {
  const [randomNumberAssistant, setRandomNumberAssistant] = useState(0);
  const [randomNumberActivities, setRandomNumberActivities] = useState(0);
  const [showText, setShowText] = useState("");
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [show, setShow] = useState(true);
  const {authorized, user} = useContext(UserContext)

  let messages = [
    `${t('first-english-program-for')} ${authorized && user.verticals[0].name != null ? user.verticals[0].name: ''}`,
    `${t('learn-and-use-key')} ${authorized && user.verticals[0].name != null ? user.verticals[0].name: ''} ${t('terminology')}`
  ];

  const generateRandomNumber = () => {
    const randomIndexAs = Math.floor(Math.random() * props.assistants.length);
    const randomIndexAc = Math.floor(Math.random() * props.activities.length);
    setRandomNumberAssistant(randomIndexAs);
    setRandomNumberActivities(randomIndexAc);
  };

  const showModalActivityCompleted = () =>{
    const modal = document.querySelector('.modalActivity')
  
    modal.classList.remove("-translate-y-full")
    modal.classList.add("translate-y-0")
  
    setTimeout(() => {
      modal.classList.add("-translate-y-full")
      modal.classList.remove("translate-y-0")
    }, 3000);
  
   }

   const showRequest = () =>{
    const modalR = document.querySelector('.modalR')
    
    modalR.classList.add("flex")
    modalR.classList.remove("hidden")
   }

  useEffect(() => {
    generateRandomNumber();

    // Configurar intervalo para actualizar el número aleatorio
    const interval = setInterval(() => {
      generateRandomNumber();
    }, 10000); // Actualizar cada 5 segundos (puedes cambiar el intervalo)

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, [props.assistants.length, props.activities.length]);

  useEffect(() => {
    const currentMessage = messages[currentMessageIndex];
    let displayText = "";
    let i = 0;

    const interval = setInterval(() => {
      displayText += currentMessage.charAt(i);
      setShowText(displayText);
      i++;
      if (i === currentMessage.length) {
        clearInterval(interval);
        setTimeout(() => {
          setShow(false)
          setTimeout(() => {
            setCurrentMessageIndex((prevIndex) => prevIndex === messages.length - 1 ? 0 : prevIndex + 1);
          setShow(true)
          }, 500); // Retraso adicional antes de iniciar la escritura del nuevo mensaje
        }, 4500); // Duración de la visibilidad del texto completo
      }
    }, 100); // Velocidad de escritura de la máquina de escribir

    return () => clearInterval(interval);
  }, [currentMessageIndex]);
  return (
    <div className="rounded-xl gap-10 bg-main2  dark:bg-white items-center justify-between hidden xl:flex my-6 relative pb-6">
      <div className="h-[300px] bg-contain overflow-hidden rounded-3xl rounded-r-none w-full z-0">
        <div className={`absolute z-10 p-10 ${authorized ? "justify-center gap-5" : "justify-between"} h-[300px] items-start flex flex-col top-0`}>
        <p className="text-lg text-white font-bold">
              {t('welcome-to-englishcode')} {authorized && ": "+user.verticals[0].name}
            </p>
          <div>
            
            <div className="w-[80%]">
            <h2
              className={`text-[1.5rem] text-bunker-50 w-full  ${
                show ? "opacity-100" : "opacity-0"
              } custom-transition`}
            >
              {showText}
            </h2>

            </div>
          </div>
          {!authorized && <div className="w-full flex justify-start items-center  custom-transition">
            <button onClick={showRequest} className="btn rounded-full bg-bunker-600 border-bunker-400 hover:bg-white hover:text-bunker-600 text-white px-16 py-2 text-sm">Request Access</button>
          </div>}
        </div>
        <div className="relative w-[800px] h-full overflow-hidden ">
          <img src={QA2} alt="Sample" className="zoom-in w-full h-auto" />
          <div className="absolute inset-0 bg-gradient-to-l from-main2 dark:from-white to-[#00000080] dark:to-[#0000009f]"></div>
        </div>
      </div>
      <div className="absolute w-1/2 h-full right-0 bottom-0 top-0 z-50 pt-6 pb-12">
        <div className="relative w-full h-full flex justify-end items-center gap-4">
          {!props.loading ? (
            props.assistants.map(
              (assistant, index) =>
                index === randomNumberAssistant && (
                  <CardSection1HomeAssistant data={assistant} key={index} />
                )
            )
          ) : (
            <CardSection1ActivityHomeSkeleton />
          )}
          {!props.loading ? (
            props.activities.map(
              (activity, index) =>
                index === randomNumberActivities && ( 
                    <CardSection1HomeActivities
                      data={activity}
                      key={index}
                      url={props.url}
                      show={showModalActivityCompleted}
                    />
                )
            )
          ) : (
            <CardSection1AssistantHomeSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default Section1Home;
