import React, { useContext } from "react";
import CardSectionActivitiesHome from "../UI/CardSectionActivitiesHome";
import CardActivityHomeSkeleton from "../UI/CardActivityHomeSkeleton";
import "swiper/css";
import "swiper/css/navigation";
import "../../assets/css/slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../utils/userContext";
import { t } from "i18next";
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('f8358a3b2025d29fe2822013871b15f1');


const SectionActivitiesHome = (props) => {
  const { loginWithRedirect } = useAuth0();
  const {authorized, user} = useContext(UserContext)

 const showModalActivityCompleted = () =>{
  const modal = document.querySelector('.modalActivity')

  modal.classList.remove("-translate-y-full")
  modal.classList.add("translate-y-0")

  setTimeout(() => {
    modal.classList.add("-translate-y-full")
    modal.classList.remove("translate-y-0")
  }, 3000);

 }

 const handleButtonClick = (props) => {
  
  if(user.id != null){
    amplitude.track('Entra a una actividad', {
      userId: user.id, 
      userEmail: user.email, 
      userName: user.full_name ,
      buttonName: 'Activity',
      page: 'Home',
      activity:props.name,
      activityId:props.parent_activity_id,
      activityScenario:props.scenario
    });
  }
};

  return (
    <div className="w-full flex flex-col gap-5 my-6">
      <h2 className="text-base font-bold text-bunker-50">{t('activities')}</h2>
      <div className="relative w-full gap-y-3 gap-1   ">
        {!authorized && (
          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center gap-2 rounded-lg bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-5 z-50">
            <h2 className="text-sm text-white font-bold">
              Join English<span className="text-bunker-500">Code</span> to
              access to activities
            </h2>
            <button
              onClick={() => loginWithRedirect()}
              className="custom-transition bg-bunker-600 rounded-full text-white hover:text-black hover:bg-[#e4e4e4] px-4 text-sm font-normal py-2"
            >
              {t('sign-in')}
            </button>
          </div>
        )}
        {user?.verticals[0].name === "Data Analyst"
        ? <div className="w-full h-full flex justify-center items-center">
          <h2 className="text-lg text-white dark:text-black">Coming soon</h2>
        </div>
        :!props.loading ? (
          <Swiper
            navigation={true}
            modules={[Navigation]}
            slidesPerView={"auto"}
            centeredSlides={false}
            spaceBetween={30}
            className="swiper-container"
          >
            {props.data.map((activity, index) => (
              <SwiperSlide key={index} className="swiper-slide">
                <CardSectionActivitiesHome data={activity} url={props.url} show={showModalActivityCompleted} clickAmplitude={handleButtonClick}/>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
          <div className="flex md:hidden w-full justify-between items-center gap-2">
            <CardActivityHomeSkeleton />
          </div>
          <div className="hidden md:flex w-full justify-between items-center gap-2">
            <CardActivityHomeSkeleton />
            <CardActivityHomeSkeleton />
            <CardActivityHomeSkeleton />
            <CardActivityHomeSkeleton />
          </div>
          </>
          
        )
      }
      </div>
    </div>
  );
};

export default SectionActivitiesHome;
