import { t } from 'i18next'
import React from 'react'
import { BsTools } from 'react-icons/bs'
import { FaCode } from 'react-icons/fa'
import { MdSpatialTracking } from 'react-icons/md'
import { SiTestcafe } from 'react-icons/si'

const TopicsAssistant = (props) => {
  return (
    <div className="w-full flex flex-col gap-4 justify-start items-start">
            <h2 className="font-semi-bold line-clamp-1 text-ellipsis text-start overflow-hidden whitespace-normal text-bunker-600 dark:text-[#7d7d7d] text-sm">
              {t('topics')}
            </h2>
            <div className="w-full flex justify-start gap-4 flex-wrap">

              <div className="w-full flex">
              <div className="w-1/2 flex flex-col justify-start items-start gap-8">
                <div className="flex items-start gap-2 w-full">
                  <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                    <FaCode   className="text-bunker-600 text-base" />
                  </div>
                  <div className="flex flex-col justify-start items-start max-w-[70%]">
                    <h3 className="text-start text-[#a3a3a3] text-sm">
                    {t('automation-frameworks')}
                    </h3>
                    <p className="text-start text-xs text-bunker-50 font-bold line-clamp-1">
                      {props.data?.automation_frameworks}
                    </p>
                  </div>
                </div>

                <div className="flex  items-start gap-2 w-full">
                  <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                    <MdSpatialTracking  className="text-bunker-600 text-base" />
                  </div>
                  <div className="flex flex-col justify-start items-start max-w-[70%]">
                    <h3 className="text-start text-[#a3a3a3] text-sm">
                    {t('defect-tracking')}
                    </h3>
                    <p className="text-start text-xs text-bunker-50 font-bold line-clamp-1">
                      {props.data?.defect_tracking}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex flex-col justify-start items-start gap-8">
                <div className="flex items-start gap-2 w-full">
                  <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                    <SiTestcafe className="text-bunker-600 text-base" />
                  </div>
                  <div className="flex flex-col justify-start items-start max-w-[70%]">
                    <h3 className="text-start text-[#a3a3a3] text-sm">
                    {t('test-management')}
                    </h3>
                    <p className="text-start text-xs text-bunker-50 font-bold line-clamp-1">
                      {props.data?.test_management}
                    </p>
                  </div>
                </div>

                <div className="flex  items-start gap-2 w-full">
                  <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-[1px] border-bunker-600">
                    <BsTools className="text-bunker-600 text-base" />
                  </div>
                  <div className="flex flex-col justify-start items-start max-w-[70%]">
                    <h3 className="text-start text-[#a3a3a3] text-sm">
                    {t('testing-tools')}
                    </h3>
                    <p className="text-start text-xs text-bunker-50 font-bold line-clamp-1">
                      {props.data?.testing_tools}
                    </p>
                  </div>
                </div>
              </div>
              </div>
              
            </div>
          </div>
  )
}

export default TopicsAssistant
