import React from "react";

const CardTeacherHomeSkeleton = () => {
  return (
    <div className="h-[380px] w-[288px] rounded-xl relative bg-[#202024] dark:bg-[#f9f9f9]">
      {/* <div className="skeleton bg-[#26272B] w-[30%] h-full rounded-xl"></div>
      <div className="w-[70%] h-full flex flex-col justify-between">
        <div className="skeleton bg-[#26272B] h-3 w-full"></div>
        <div className="skeleton bg-[#26272B] h-3 w-10"></div>
        <div className="skeleton bg-[#26272B] h-3 w-40"></div>
        <div className="skeleton bg-[#26272B] h-3 w-40"></div>
        <div className="skeleton bg-[#26272B] h-3 w-28"></div>
      </div> */}

      <div className="w-full h-1/2 flex justify-center items-center">
        <div className="w-full h-full  object-cover rounded-t-xl skeleton bg-[#26272B]"/>
      </div>
      <div className="w-full h-1/2 flex flex-col justify-between p-3">
        <div className="flex flex-col justify-between gap-2">
        <div className="skeleton bg-[#26272B] h-3 w-full"></div>
        <div className="skeleton bg-[#26272B] h-3 w-10"></div>
        <div className="skeleton bg-[#26272B] h-3 w-full"></div>
        </div>
      </div>
    </div>
  );
};

export default CardTeacherHomeSkeleton;
