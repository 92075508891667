import { useAuth0 } from "@auth0/auth0-react";
import { t } from "i18next";
import React from "react";
import { MdNoEncryptionGmailerrorred } from "react-icons/md";

const ModalInactive = () => {
  const { logout } = useAuth0();

  const showRequest = () => {
    const modalR = document.querySelector(".modalR");

    modalR.classList.add("flex");
    modalR.classList.remove("hidden");
  };

  return (
    <div className="absolute hidden modalI h-full w-full justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-[60] top-0">
      <div className="relative rounded-lg bg-pr-900 flex flex-col justify-between items-center  w-[30%] p-10 gap-10">
        {/* <IoClose
          onClick={hideModalScenario}
          className="absolute top-4 right-4 text-lg cursor-pointer text-white"
        /> */}
        <h2 className="text-xl font-bold text-bunker-600 text-center">
          {t('your-account-is-inactive')} <br /> <br />{" "}
          <MdNoEncryptionGmailerrorred className="text-5xl text-bunker-600 text-center mx-auto" />
        </h2>
        <p className="text-sm w-[90%] text-center">
          {t('inactive')}
        </p>
        <div className="w-full flex justify-center items-center gap-4">
          <button
            onClick={() =>
              logout({
                logoutParams: { returnTo: window.location.origin },
              })
            }
            className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
          >
            {t('exit')}
          </button>
          <button
            onClick={showRequest}
            className="btn bg-bunker-900  hover:bg-pr-800 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d]  text-white text-xs font-semibold rounded-xl custom-transition"
          >
            {t('contact-us')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalInactive;
