import React from "react";
import { IoClose } from "react-icons/io5";

const OverlayWord = (props) => {
  const hideModal = () =>{
    const modal = document.querySelector(".modalW")

    modal.classList.add("hidden")
    modal.classList.remove("flex")
    props.dataSet(null)

  }

  return (
    <div className="absolute flex modalW h-full w-full justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-[60] top-0">
      <div className="w-auto relative max-w-[80%] min-w-[40%] p-5 flex flex-col justify-between items-center gap-5 bg-pr-900 rounded-lg">
        <IoClose onClick={hideModal} className="absolute top-2 right-2 text-white cursor-pointer"/>
        <div className="w-full flex flex-col gap-2 justify-center items-center">
          <h1 className="text-lg text-white font-semibold">
            {props.data.word}
          </h1>
          <h2 className="text-base text-white">
            Pronunciation Score:{" "}
            <span className="font-semibold text-bunker-600">
              {props.data.quality_score}
            </span>
          </h2>
        </div>
        <span className="w-full h-px bg-bunker-800"/>
        <div className="flex flex-col gap-10 w-auto justify-center items-center">
          <div className="flex flex-col gap-5 justify-center items-center">
            <h2 className="text-sm font-bold">Phonemes:</h2>
            <table class="table-auto w-[500px] border-collapse text-sm">
              <thead>
                <tr>
                  <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pr-0 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">Phone</th>
                  <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pr-0 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">Score</th>
                  <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pr-0 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">Sound Most Like</th>
                </tr>
              </thead>
              <tbody className="bg-pr-600">
                {props.data.phone_score_list.map((phoneme, index) => (
                  <tr>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 pr-0  dark:text-slate-400 text-white"> {phoneme.phone}</td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 pr-0  dark:text-slate-400 text-white"> { phoneme.quality_score % 1 !== 0 ? parseFloat(phoneme.quality_score.toFixed(1)) : phoneme.quality_score}</td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 pr-0  dark:text-slate-400 text-white"> {phoneme.sound_most_like}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayWord;
