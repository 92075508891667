import React, { useContext, useState } from "react";
import { UserContext } from "../utils/userContext";
import axios from "axios";
import SplashCV from "../pages/SplashCV";
import FormUploadCVSettings from "../UI/FormUploadCVSettings";
import { t } from "i18next";

const AboutMeSettings = () => {
  const [dataResume, setDataResume] = useState();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState(null);

  const { user, setUser } = useContext(UserContext);

  const [show, setShow] = useState(false);
  const token = window.localStorage.getItem("token_user")


  const seeMore = () => {
    const bio = document.querySelector(".bio");
    bio.classList.add("line-clamp-none");
    bio.classList.remove("line-clamp-5");
    setShow(true);
  };

  const seeLess = () => {
    const bio = document.querySelector(".bio");
    bio.classList.remove("line-clamp-none");
    bio.classList.add("line-clamp-5");
    setShow(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const btnSubmit = document.querySelector(".submit2");
    const file = e.dataTransfer.files[0];

    if (file) {
      if (validateFile(file)) {
        setDataResume(file);
        setFileName(file.name);
        setError(null);
        btnSubmit.style.display = "flex";
      } else {
        setDataResume(null);
        setFileName("");
        btnSubmit.style.display = "none";
      }
    }
  };

  const handleResume = (e) => {
    const btnSubmit = document.querySelector(".submit2");
    const file = e.target.files[0];

    if (file) {
      if (validateFile(file)) {
        setDataResume(file);
        setFileName(file.name);
        setError(null);
        btnSubmit.style.display = "flex";
      } else {
        setDataResume(null);
        setFileName("");
        btnSubmit.style.display = "none";
      }
    }
  };

  const showModalInactive = () =>{
    const modalI = document.querySelector('.modalI')
    modalI.classList.add("flex")
    modalI.classList.remove("hidden")

  }

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();

    formData.append("cv", dataResume);

    axios
      .post(process.env.REACT_APP_URL_CV + user.id + "/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          "Authorization": "Bearer "+token
        },
      })
      .then((res) => {
        getUser();
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        if(err.response.data.detail === "User is inactive"){
          showModalInactive()
        }
      });
  };

  const validateFile = (file) => {
    // Validar el tipo de archivo (PDF)
    if (file.type !== "application/pdf") {
      setError("The file must be a PDF.");
      return false;
    }
    // Validar el tamaño del archivo (2MB)
    if (file.size > 2 * 1024 * 1024) {
      setError("The file is too large. The maximum size is 2MB.");
      return false;
    }
    return true;
  };

  const getUser = () => {
    axios
      .get(process.env.REACT_APP_URL_GET_USERS + user.id + "/", {
        headers: {
          "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          "Authorization": "Bearer "+token
        },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        if(err.response.data.detail === "User is inactive"){
          showModalInactive()
        }
      });
  };

  return (
    <>
    <div className="flex flex-col h-full overflow-y-scroll pb-32 md:pb-3">
      <h1 className="text-bunker-500 text-xl mb-10 font-medium">{t('about-me')}</h1>
      <div className="flex flex-col gap-2 ">
        <div className="flex flex-col gap-1 justify-center items-center">
          <div>
            <h2 className="text-bunker-50 text-base">{t('my-bio')}</h2>
            <p className="bio text-sm line-clamp-5">
              {user != null && user?.bio}
            </p>
            {show ? (
              <span
                className="text-bunker-50 text-xs hover:text-[#f9f9f9]  cursor-pointer"
                onClick={seeLess}
              >
                {t('see-less')}
              </span>
            ) : (
              <span
                className="text-bunker-50 text-xs hover:text-[#f9f9f9]  cursor-pointer"
                onClick={seeMore}
              >
                {t('see-more')}
              </span>
            )}
          </div>
          <span className="shrink-0 block bg-pr-200 h-[1px] w-full my-3 "></span>
          <div className="w-full max-w-full">
            <h2 className="text-bunker-50 text-base">{t('my-linkedin')}</h2>
            <a
              href={user != null ? user.linkedin: ''}
              className="text-sm hover:underline line-clamp-2"
              target="_blank"
              rel="noreferrer"
            >
              {user != null && user.linkedin}
            </a>
          </div>
          <span className="shrink-0 block bg-pr-200 h-[1px] w-full my-3 "></span>
          <div>
            <h2 className="text-bunker-50 text-base">{t('my-skills')}</h2>
            <ul className="flex flex-wrap w-full list-inside list-disc gap-2 mt-2">
              {user != null &&
                user.skills.map((skill, index) => (
                  <li key={index} className=" text-xs">
                    {skill}
                  </li>
                ))}
            </ul>
          </div>
          <span className="shrink-0 block bg-pr-200 h-[1px] w-full my-3 "></span>
          <div className="flex flex-col gap-2">
            <h2 className="text-bunker-50 text-base text-center">{t('resend-your-cv')}</h2>
            <p className="text-xs text-center">
              {t('if-the-information-we-provided-is-not-accurate-please-resend-your-resume')}
            </p>
            <FormUploadCVSettings
              handleSubmit={handleSubmit}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fileName={fileName}
              handleResume={handleResume}
              error={error}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
    {loading && <SplashCV loading={loading}/>}
    </>
  );
};

export default AboutMeSettings;
