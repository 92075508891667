import { t } from "i18next";
import React from "react";
import { FaHourglassHalf, FaHourglassStart } from "react-icons/fa";

const LevelBasicEnglish = () => {
  return (
    <div className="w-full flex flex-col gap-10 p-2">
      {/* <div className="w-full flex flex-col gap-2">
      <h2 className="text-bunker-50 text-base">You have <strong>BASIC</strong> English <strong>A1/A2</strong></h2>
      <p className="text-sm">
        You find it difficult to understand, express clearly and fluently use
        basic English terms or form complete sentences.
      </p>
      </div> */}
      <div className="w-full flex flex-col justify-center items-center mt-2">
        <div className="relative w-[90%] rounded-lg border border-bunker-800 flex flex-col justify-center items-center  p-4 pt-3">
          <h2 className="absolute left-1/2 -translate-x-1/2 -top-5 text-bunker-50 font-bold text-base text-center bg-pr-600 p-2">
            {t('basico')}
          </h2>
          <FaHourglassStart className="text-xl text-bunker-600 text-center my-5" />

          <p className="text-xs text-center">{t('basico-1')}</p>
        </div>
        <div className="w-full h-[150px] flex flex-col justify-center items-center">
          <span className="w-px h-full bg-bunker-600"></span>
          <span className="w-px h-full bg-bunker-600"></span>
        </div>
        <div className="relative w-[90%] rounded-lg border border-bunker-800 flex flex-col justify-center items-center p-4 pt-3">
          <h2 className="absolute left-1/2 -translate-x-1/2 -top-5 text-bunker-50 font-bold text-base text-center bg-pr-600 p-2">
          {t('intermedio')}
          </h2>
          <FaHourglassHalf className="text-xl text-bunker-600 text-center my-5" />

          <p className="text-xs text-center">{t('basico-2')}</p>
        </div>
      </div>
    </div>
  );
};

export default LevelBasicEnglish;
