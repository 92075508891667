import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { GrStatusGood } from "react-icons/gr";
import { IoClose } from "react-icons/io5";

const ModalRequest = () => {

    const [dataEmail, setDataEmail] = useState({
        email:""
    })
    const [loading, setLoading] = useState(false)
    const [sended, setSended] = useState(false)

    const {logout} = useAuth0()

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) =>{
    setDataEmail({
        email: e.target.value
    })
  }

  const sendRequest = () =>{
    setLoading(true)
    axios.post("https://formspree.io/f/xwpebana", dataEmail)
    .then((res)=>{
        setLoading(false)
        setSended(true)
        logout()
    })
    .catch((err)=>{
        setLoading(false)
        
    })
  }

  const hideModalRequest = () =>{
    const modalR = document.querySelector('.modalR')
    modalR.classList.remove("flex")
    modalR.classList.add("hidden")

  }



  return (
    <div className="absolute hidden modalR h-full w-full justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-[70]">
        
      <div className="relative rounded-lg bg-pr-900 p-5 py-10">
      <IoClose
            onClick={hideModalRequest}
            className="absolute top-4 right-4 text-lg cursor-pointer text-white"
          />
        {!sended && <h2 className="text-center text-base font-bold text-white mb-10">
          {t('send-us-your-email-and-we-will-contact-you')}
        </h2>}
        {sended
        ?<div className="w-full h-full flex flex-col justify-center items-center gap-2">
            <GrStatusGood className="text-green-400 text-6xl" />
            <h2 className="text-base text-white font-bold text-center">{t('your-email-was-sent-successfully-we-will-contact-you')}</h2>
        </div>  
        :<form onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center gap-4">
        <div className="flex-col w-full relative border rounded-spacing-s py-4 text-md file:border-0 file:bg-transparent file:text-md file:font-medium placeholder:text-placeholder outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 appearance-none px-3  pb-[15px] pt-[23px] placeholder:text-md bg-pr-800  border-bunker-800 custom-transition hover:border-bunker-600 focus-visible:border-[#fafafa] rounded-xl">
          <label
            htmlFor="email2"
            className="absolute top-2 left-3 text-xs text-bunker-50"
          >
            {t('email')}
          </label>
          <input
          onChange={handleChange}
          name="email"
            type="email"
            id="email2"
            className="w-full outline-none bg-transparent text-bunker-50 text-sm"
          />
        </div>
        <button onClick={sendRequest} className="btn-sm rounded-full bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold custom-transition">
          {loading
          ? <span className="loading loading-spinner loading-xs text-white"></span>
          :t('send-request')
          }
        </button>
      </form>
        }
      </div>
    </div>
  );
};

export default ModalRequest;
